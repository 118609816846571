import TypeRegroupement from '../../constants/typeRegroupement';
import BaseTypeRegroupement from './BaseTypeRegroupement';
import type { TreeRow } from '../../interfaces/treeRow';
import type BaseFeuilleSaisie from '../FeuilleSaisie/BaseFeuilleSaisie';

class ImputationTypeRegroupement extends BaseTypeRegroupement {
  constructor() {
    super();
    this.code = TypeRegroupement.IMPUTATION;
    this.codeColumnName = '';
    this.icon = {
      iconSet: 'Lucide',
      name: 'ZoomIn',
    };
    this.libelle = 'Imputation';
  }

  getLibelle(_data : TreeRow, _feuille: BaseFeuilleSaisie): string {
    return '';
  }

  getKey(data : TreeRow): string {
    // eslint-disable-next-line max-len
    return `${data.idcm_chapitre}-${data.idcm_article}-${data.idcm_operation ?? ''}-${data.idcm_fonction ?? ''}-${data.idcm_analytique ?? ''}-${data.idcm_ventilation ?? ''}`;
  }
}

export default ImputationTypeRegroupement;
