import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import FamilleControleCoherence from '../../enums/FamilleControleCoherence';
import StatutControleCoherence from '../../enums/StatutControleCoherence';
import BaseControleCoherence from './BaseControleCoherence';
import type MontantControleCoherence from '../../interfaces/MontantControleCoherence';

class PlafondDepenseImprevuFonctionnement extends BaseControleCoherence {
  constructor(montants: MontantControleCoherence) {
    super(montants);
    this.famille = FamilleControleCoherence.AUTRES;
  }

  getExplicatif(): string {
    const depenseFonctionnement022 = formatMontant(this.montants.depenseFonctionnement022, 2, true);
    const { depenseFonctionnementReelle } = this.montants;

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return `Les crédits inscrits au 022 (${depenseFonctionnement022}) sont strictement supérieur aux 7,5% des dépenses réelles de fonctionnement hors restes à réaliser (${depenseFonctionnementReelle} x 7,5% = ${formatMontant(depenseFonctionnementReelle * 0.075, 2, true)}).`;
      case StatutControleCoherence.OK:
        return `Les crédits inscrits au 022 (${depenseFonctionnement022}) sont inférieurs aux 7,5% des dépenses réelles de fonctionnement hors restes à réaliser (${depenseFonctionnementReelle} x 7,5% = ${formatMontant(depenseFonctionnementReelle * 0.075, 2, true)}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return 'Non-respect du plafond des dépenses imprévues en fonctionnement';
      case StatutControleCoherence.OK:
        return 'Respect du plafond des dépenses imprévues en fonctionnement';
      default:
        return '';
    }
  }

  getStatut(): number {
    const { depenseFonctionnement022, depenseFonctionnementReelle } = this.montants;

    // DF_022 <= 7,5% de depenseFonctionnementReelle
    if (depenseFonctionnement022 <= (depenseFonctionnementReelle * 0.075)) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default PlafondDepenseImprevuFonctionnement;
