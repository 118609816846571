/* eslint-disable no-loop-func */
import React from 'react';
import {
  Currency,
  Form,
  Input,
  Tooltip,
} from '@jvs-group/jvs-mairistem-composants';
import { Table } from 'rsuite-table';
import {
  isNil,
  isUndefined,
} from 'lodash';
import { toast } from 'react-toastify';
import { Icon, getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import type BaseFeuilleSaisie from '../../FeuilleSaisie/classes/FeuilleSaisie/BaseFeuilleSaisie';
import {
  extractCodeFromKey,
  fetchData,
  getMontantColumnFromPeriode,
  getMontantImputation,
  getMontantNameFromPeriode,
} from '../../FeuilleSaisie/utils/imputation';
import { TreeRow } from '../../FeuilleSaisie/interfaces/treeRow';
import { calculImputations, shouldAddReport, upsertMontant } from '../utils/simulation';
import type Simulation from '../interfaces/simulation';
import ROW_HEIGHT from '../../FeuilleSaisie/constants/tableProps';
import TypePeriode from '../enums/typePeriode';
import TypeLigneMontant from '../enums/typeLigneMontant';
import type MontantSimulation from '../../../interfaces/montantSimulation';
import UserRole from '../../../constants/userRole';
import TypeBudget from '../enums/typeBudget';
import CalculPropositionModal from '../../Outils/components/CalculProposition/CalculPropositionModal';
import type RegleCalcul from '../interfaces/regleCalcul';
import { renderMontantText } from '../utils/montant';
import {
  TreeSymbol,
  getRowFilters,
  getSymbol,
  renderTreeToggle,
} from '../../../utils/treeView';
import type Filters from '../interfaces/filters';
import 'rsuite-table/lib/less/index.less';
import './ImputationTable.less';

const TYPE_CHAMP = {
  DEMANDE: 0,
  PROPOSE: 1,
  VOTE: 2,
  CA: 3,
  RAR: 4,
  COMMENTAIRE: 5,
};

const KEY_CODE = {
  DOWN: 40,
  UP: 38,
  ENTER: 13,
};

const renderLibelleRow = (rowData: TreeRow, expandedRowKeys: string[], feuilleSaisie: BaseFeuilleSaisie) => {
  const typeRegroupementIcon = feuilleSaisie.nodes[(getSymbol(rowData, TreeSymbol.DEPTH) ?? 0) + 1][0].icon;

  return (
    <>
      {typeRegroupementIcon && (
        <Icon
          className="typeRegroupementIcon"
          size={20}
          {...typeRegroupementIcon}
        />
      )}
      <span
        className={[
          'noeudLibelleGroup',
          ...rowData.isordre ? ['italic'] : [],
          ...expandedRowKeys.includes(rowData?.key) ? ['bold'] : [],
        ].join(' ')}
        title={rowData.libelle.join(' | ')}
      >
        {rowData.libelle.map((libelle, index) => (
          <span
            className="noeudLibelle"
            style={{
              ...(index !== (rowData.libelle.length - 1))
                ? { maxWidth: `${100 / rowData.libelle.length}%` }
                : { flex: 1 },
            }}
          >
            {index !== 0 ? '| ' : ''}
            {libelle}
          </span>
        ))}
      </span>
    </>
  );
};

const stopPropagationInput = (e: React.MouseEvent) => e.stopPropagation();

const focusField = (input : HTMLInputElement) => {
  setTimeout(() => {
    input?.focus();
    input?.select();
    input?.scrollIntoView({ block: 'center' });
  }, 0);
};

export interface OutilModalData {
  rowData: TreeRow;
  filters: Filters,
  feuilleSaisie: BaseFeuilleSaisie;
}

interface ImputationTableProps {
  data: TreeRow[];
  feuilleSaisie: BaseFeuilleSaisie;
  filters: Filters;
  handleDataChange: any;
  key: string,
  onChangeMontant: any;
  onRefresh: (onlyPanelData: boolean) => Promise<void>;
  setIsFocusingInput: React.Dispatch<React.SetStateAction<boolean>>;
  simulation: Simulation;
  userRole: UserRole;
}

const ImputationTable = ({
  data,
  feuilleSaisie,
  filters,
  handleDataChange,
  key,
  onChangeMontant,
  setIsFocusingInput,
  simulation,
  userRole = UserRole.NORMAL,
  onRefresh,
}: ImputationTableProps) => {
  const [actualLoadingRowIdentifiant, setActualLoadingRowIdentifiant] = React.useState<string>('');
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  const [outilModalOpen, setOutilModalOpen] = React.useState<boolean>(false);
  const [outilModalData, setOutilModalData] = React.useState<OutilModalData>();

  // Permet de savoir si on désactive les champs par manque d'habilitation
  const isInputEnabled = React.useCallback((): boolean => {
    // Si on est transféré en compta, plus de modification de montant
    if (simulation?.transfertCpta) return false;

    switch (simulation?.typePeriode) {
      case TypePeriode.DEMANDE:
        return userRole === UserRole.ADMIN || userRole === UserRole.GESTIONNAIRE;
      case TypePeriode.PROPOSE:
      case TypePeriode.VOTE:
        return userRole === UserRole.ADMIN;
      default:
        return true;
    }
  }, [simulation?.typePeriode, userRole]);

  const handleExpandFocus = (expandedRow : TreeRow) => {
    setIsFocusingInput(true);

    const observer = new window.MutationObserver(() => {
      let field = null;
      switch (simulation?.typePeriode) {
        case TypePeriode.DEMANDE:
          field = TYPE_CHAMP.DEMANDE;
          break;
        case TypePeriode.VOTE:
          field = TYPE_CHAMP.VOTE;
          break;
        case TypePeriode.PROPOSE: default:
          field = TYPE_CHAMP.PROPOSE;
          break;
      }

      const input : HTMLInputElement = document.querySelector(
        `[data-key="0-${expandedRow.keyOrder}"][data-field="${field}"]`,
      );

      focusField(input);
      setIsFocusingInput(false);

      observer.disconnect();
    });

    observer.observe(document.querySelector('.rs-table'), {
      attributes: true,
    });
  };

  const handleExpandChange = async (isOpen: boolean, expandedRow: TreeRow) => {
    // On toggle la cle dans notre liste
    setExpandedRowKeys((old) => (isOpen ? [...old, expandedRow?.key] : old.filter((key) => key !== expandedRow?.key)));

    try {
      // On gere l'icone loading
      setActualLoadingRowIdentifiant(expandedRow?.key);

      // Permet de ne pas rappeller l'api si les donnees sont deja chargees
      if (!isOpen || expandedRow.children.length > 0) {
      // Permet de ne pas faire d'observeur si on ferme le noeud
        if (isOpen) handleExpandFocus(expandedRow);
        setActualLoadingRowIdentifiant(null);
        return;
      }

      const initialDepth = (getSymbol(expandedRow, TreeSymbol.DEPTH) ?? 0) + 1;
      const childrens = await fetchData(
        simulation,
        feuilleSaisie,
        feuilleSaisie.nodes[initialDepth + 1],
        getRowFilters(expandedRow, filters, feuilleSaisie),
      );

      let fullItem: TreeRow = {
        ...expandedRow,
        children: childrens,
      };

      let parent = getSymbol(expandedRow, TreeSymbol.PARENT);

      // Tant qu'on est pas a la racine
      while (parent !== undefined) {
        fullItem = {
          ...parent,
          children: parent.children.map((child) => {
            if (child.key === fullItem.key) {
              return fullItem;
            }
            return child;
          }),
        };
        parent = getSymbol(parent, TreeSymbol.PARENT);
      }

      handleDataChange(data.map((imput) => (imput?.key === fullItem?.key ? fullItem : imput)));
      handleExpandFocus(expandedRow);
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors du chargement du noeud'));
    } finally {
      setActualLoadingRowIdentifiant(null);
    }
  };

  const upsertLigne = (data: any, rowData: TreeRow) => upsertMontant({
    ...data,
    identifiantSimulation: simulation.identifiant,
    identifiantChapitre: rowData?.idcm_chapitre,
    identifiantArticle: rowData?.idcm_article,
    identifiantOperation: rowData?.idcm_operation,
    identifiantFonction: rowData?.idcm_fonction,
    identifiantAnalytique: rowData?.idcm_analytique ?? null,
    identifiantVentilation: rowData?.idcm_ventilation ?? null,
    sens: rowData?.imp_sens,
  });

  const handleChangeMontantIndicateurCle = (differenceMontant: number, rowData: TreeRow) => {
    switch (simulation.typePeriode) {
      case TypePeriode.DEMANDE:
        onChangeMontant({ demande: differenceMontant }, rowData);
        break;
      case TypePeriode.PROPOSE:
        onChangeMontant({ prop: differenceMontant }, rowData);
        break;
      case TypePeriode.VOTE:
        onChangeMontant({ vote: differenceMontant }, rowData);
        break;
      default:
        break;
    }
  };

  const handleChangeMontant = async (montant: string, rowData: TreeRow) => {
    if (montant === rowData?.[getMontantNameFromPeriode(simulation?.typePeriode)]) {
      return;
    }

    let columnMontant = getMontantColumnFromPeriode(simulation?.typePeriode);
    const response : any = await upsertLigne({
      [columnMontant]: montant,
    }, rowData);

    const differenceMontant = Number(montant) - getMontantImputation(rowData, simulation.typePeriode);
    handleChangeMontantIndicateurCle(differenceMontant, rowData);

    let parent = getSymbol(rowData, TreeSymbol.PARENT);
    columnMontant = getMontantNameFromPeriode(simulation?.typePeriode);

    let fullItem = {
      ...rowData,
      idsi_mt_simul: response.identifiant,
      [columnMontant]: Number(montant),
    };

    while (parent !== undefined) {
      fullItem = {
        ...parent,
        [columnMontant]: Number(getMontantImputation(parent, simulation.typePeriode)) + differenceMontant,
        children: parent.children?.map((child) => {
          if (child.key === fullItem.key) {
            return fullItem;
          }
          return child;
        }),
      };
      parent = getSymbol(parent, TreeSymbol.PARENT);
    }

    handleDataChange(data.map((imput) => (imput?.key === fullItem?.key ? fullItem : imput)));
  };

  const handleChangeMontantReport = async (montant: string, rowData: TreeRow) => {
    if (Number(montant) === rowData?.report) return;

    const montantData: Partial<MontantSimulation> = { rep: Number(montant) };

    switch (simulation?.typePeriode) {
      case TypePeriode.DEMANDE:
        montantData.type = TypeLigneMontant.SAI_LIB;
        break;
      case TypePeriode.PROPOSE:
      case TypePeriode.VOTE:
        montantData.type = TypeLigneMontant.PROP_DIR;
        break;
      default:
        break;
    }

    const response : any = await upsertLigne(montantData, rowData);

    let parent = getSymbol(rowData, TreeSymbol.PARENT);
    const differenceMontant = Number(montant) - rowData.report;
    handleChangeMontantIndicateurCle(differenceMontant, rowData);

    let fullItem = {
      ...rowData,
      report: Number(montant),
      idsi_mt_simul: response.identifiant,
    };

    while (parent !== undefined) {
      fullItem = {
        ...parent,
        report: Number(parent.report) + differenceMontant,
        children: parent.children?.map((child) => {
          if (child.key === fullItem.key) {
            return fullItem;
          }
          return child;
        }),
      };

      parent = getSymbol(parent, TreeSymbol.PARENT);
    }

    handleDataChange(data.map((imput) => (imput?.key === fullItem?.key ? fullItem : imput)));
  };

  const handleChangeCommentaire = async (e: any, rowData: TreeRow) => {
    const commentaire = e.currentTarget.value;

    if (commentaire === rowData?.mtsi_commentaire) return;

    const montantData: Partial<MontantSimulation> = { commentaire };

    switch (simulation?.typePeriode) {
      case TypePeriode.DEMANDE:
        montantData.type = TypeLigneMontant.SAI_LIB;
        break;
      case TypePeriode.PROPOSE:
      case TypePeriode.VOTE:
        montantData.type = TypeLigneMontant.PROP_DIR;
        break;
      default:
        break;
    }

    const response: any = await upsertLigne(montantData, rowData);
    let fullItem = {
      ...rowData,
      mtsi_commentaire: commentaire,
      idsi_mt_simul: response.identifiant,
    };

    let parent = getSymbol(rowData, TreeSymbol.PARENT);

    while (parent !== undefined) {
      fullItem = {
        ...parent,
        children: parent.children?.map((child) => {
          if (child.key === fullItem.key) {
            return fullItem;
          }
          return child;
        }),
      };

      parent = getSymbol(parent, TreeSymbol.PARENT);
    }

    handleDataChange(data.map((imput) => (imput?.key === fullItem?.key ? fullItem : imput)));
  };

  const handleInputKeyDown = async (e: any) => {
    if (![KEY_CODE.ENTER, KEY_CODE.DOWN, KEY_CODE.UP].includes(e.keyCode)) {
      return;
    }
    const targetField = e?.currentTarget?.dataset?.field;

    const parent = e?.currentTarget.closest('.imputationTableRow');
    let nextSibling = e.keyCode === KEY_CODE.UP ? parent?.previousElementSibling : parent?.nextElementSibling;
    let nextSiblingChevron = nextSibling?.querySelector(
      '.rs-table-cell-expand-wrapper .chevron',
    );

    // on parcours les chevrons depliés
    while (!isNil(nextSiblingChevron) && nextSiblingChevron?.classList.contains('down')) {
      nextSibling = e.keyCode === KEY_CODE.UP ? nextSibling?.previousElementSibling : nextSibling?.nextElementSibling;
      nextSiblingChevron = nextSibling?.querySelector(
        '.rs-table-cell-expand-wrapper .chevron',
      );
    }

    if (nextSiblingChevron && (e.keyCode === KEY_CODE.DOWN || e.keyCode === KEY_CODE.ENTER)) {
      nextSiblingChevron?.click();

      let currentIndex = Number(parent.getAttribute('aria-rowindex')) + 1;
      const observer = new window.MutationObserver((mutations, observer) => {
        // on filtre pour ne pas reagir a toutes les mutations
        const lastChange = mutations?.find(
          (mutation) => {
            const target = mutation.target as HTMLElement;
            return target?.classList.contains('rs-table-treetable');
          },
        );
        if (lastChange) {
          const target = document.querySelector(`.imputationTableRow[aria-rowindex="${currentIndex + 1}"]`);
          const inputTarget : HTMLInputElement = target?.querySelector(`input[data-field="${targetField}"]`);

          // si on a un input alors on a fini de deplier
          if (inputTarget) {
            observer.disconnect();
            focusField(inputTarget);
          } else if (!isNil(target)) {
            const targetChevron : HTMLInputElement = target.querySelector('.rs-table-cell-expand-wrapper .chevron');
            targetChevron?.click();
            currentIndex += 1;
          }
        }
      });

      observer.observe(document.querySelector('.rs-table'), {
        attributes: true,
      });
    } else {
      const inputTarget : HTMLInputElement = nextSibling?.querySelector(`input[data-field="${targetField}"]`);
      focusField(inputTarget);
    }
  };

  const renderCommentaireCell = (rowData: TreeRow) => {
    const parent = getSymbol(rowData, TreeSymbol.PARENT);
    const key = `${rowData.index}-${parent?.keyOrder ?? ''}`;

    return (
      <Input
        title={rowData?.mtsi_commentaire}
        disabled={!isInputEnabled()}
        key={rowData.index}
        onBlur={(e) => handleChangeCommentaire(e, rowData)}
        value={rowData?.mtsi_commentaire}
      >
        <input
          data-field={TYPE_CHAMP.COMMENTAIRE}
          data-key={key}
          data-testid={`commentaireInput${key}`}
          onClick={stopPropagationInput}
          onKeyDown={handleInputKeyDown}
        />
      </Input>
    );
  };

  const renderMontantInput = (rowData: TreeRow, typeChamp: number) => {
    const parent = getSymbol(rowData, TreeSymbol.PARENT);
    const key = `${rowData.index}-${parent?.keyOrder ?? ''}`;

    let disabled = !isInputEnabled();
    if (typeChamp === TYPE_CHAMP.CA) {
      disabled = disabled || rowData?.ope_code === 'OPFI' || rowData?.isordre;
      if (rowData?.cha_section === 'F' && !simulation?.exercice?.budReportFonc) {
        disabled = true;
      }
    }

    return (
      <Form>
        <Currency
          disabled={disabled}
          onBlur={(e, montant: string) => handleChangeMontant(montant, rowData)}
          value={getMontantImputation(rowData, simulation?.typePeriode)}
        >
          <input
            className="currency"
            data-field={typeChamp}
            data-key={key}
            data-testid={`montantInput${key}`}
            onClick={stopPropagationInput}
            onKeyDown={handleInputKeyDown}
          />
        </Currency>
      </Form>
    );
  };

  const renderMontantReportCell = (rowData: TreeRow) => {
    if (isUndefined(rowData?.nombre_montant)
    || (rowData?.cha_section === 'F' && !simulation?.exercice?.budReportFonc)
    || rowData?.ope_code === 'OPFI'
    || rowData?.isordre) {
      return renderMontantText(rowData.report, expandedRowKeys.includes(rowData.key));
    }

    const parent = getSymbol(rowData, TreeSymbol.PARENT);
    const key = `${rowData.index}-${parent?.keyOrder ?? ''}`;

    return (
      <Form>
        <Currency
          disabled={!isInputEnabled()}
          onBlur={(e, montant: string) => handleChangeMontantReport(montant, rowData)}
          value={rowData.report}
        >
          <input
            className="currency"
            data-field={TYPE_CHAMP.RAR}
            data-key={key}
            data-testid={`montantReport${key}`}
            onClick={stopPropagationInput}
            onKeyDown={handleInputKeyDown}
          />
        </Currency>
      </Form>
    );
  };

  const handleOpenCalculatriceModal = async (rowData: TreeRow) => {
    setOutilModalData({
      rowData,
      filters,
      feuilleSaisie,
    });
    setOutilModalOpen(true);
  };

  const handleOutilModalClose = () => setOutilModalOpen(false);

  const handleOutilModalValidate = async (row: TreeRow, regleCalcul: RegleCalcul) => {
    await calculImputations(
      simulation?.identifiant,
      getRowFilters(row, filters, feuilleSaisie),
      {
        identifiantRegleCalcul: regleCalcul.identifiant,
        typeArrondi: regleCalcul.typeArrondi,
        pourcentageMajoration: regleCalcul.pourcentage,
        montantForfaitaire: regleCalcul.forfaitaire,
        codeFeuille: feuilleSaisie.code,
      },
    );

    // on recharge l'element courant et ses freres afin de mettre a jour les montants
    let parent = getSymbol(row, TreeSymbol.PARENT);

    const initialDepth = getSymbol(row, TreeSymbol.DEPTH) ?? 0;
    const siblings = await fetchData(
      simulation,
      feuilleSaisie,
      feuilleSaisie.nodes[initialDepth + 1],
      getRowFilters(parent, filters, feuilleSaisie),
    );

    const rowCode = extractCodeFromKey(row.key);
    const newRow = siblings.find((sibling) => extractCodeFromKey(sibling.key) === rowCode);
    const differenceMontant = newRow.montant_propose - row.montant_propose;

    let fullItem = {
      ...row,
      montant_propose: newRow.montant_propose,
      // si row.children est null, on est sur un noeud final
      children: isNil(row.children) ? null : [],
    };

    // on met a jour le total de la ligne courante
    while (parent !== undefined) {
      fullItem = {
        ...parent,
        montant_propose: Number(getMontantImputation(parent, TypePeriode.PROPOSE)) + differenceMontant,
        children: parent.children?.map((child) => {
          if (child.key === fullItem.key) {
            return fullItem;
          }
          return child;
        }),
      };
      parent = getSymbol(parent, TreeSymbol.PARENT);
    }

    handleDataChange(data.map((imput) => (imput?.key === fullItem?.key ? fullItem : imput)));
    setExpandedRowKeys((old) => old.filter((key) => key !== row?.key));
    await onRefresh?.(true);
    setOutilModalOpen(false);
  };

  const renderOutilCell = (rowData: TreeRow) => userRole > UserRole.GESTIONNAIRE
    && simulation?.typePeriode === TypePeriode.PROPOSE
    && (
      <Icon
        cursor="pointer"
        data-testid={`calculatrice${rowData.key}`}
        iconSet="Lucide"
        name="Calculator"
        onClick={() => handleOpenCalculatriceModal(rowData)}
      />
    );

  const handleRowClick = (rowData: TreeRow) => {
    // Si je ne suis pas sur une ligne finale
    if (isUndefined(rowData.nombre_montant)) {
      return;
    }

    // On cherche l'input de notre periode
    const field = simulation?.typePeriode;

    const parent = getSymbol(rowData, TreeSymbol.PARENT);
    const key = `${rowData.index}-${parent?.keyOrder ?? ''}`;
    const currentTarget: HTMLInputElement = document.querySelector(`input[data-field="${field}"][data-key="${key}"]`);

    focusField(currentTarget);
  };

  return (
    <>
      <Table<TreeRow, string>
        autoHeight
        data={data}
        expandedRowKeys={expandedRowKeys}
        hover={false}
        loading={data.length === 0}
        isTree
        key={key}
        onExpandChange={handleExpandChange}
        onRowClick={handleRowClick}
        renderTreeToggle={(
          expandButton,
          { key },
          isExpanded,
        ) => renderTreeToggle(key, isExpanded, actualLoadingRowIdentifiant)}
        rowKey="key"
        rowClassName="imputationTableRow"
        rowHeight={ROW_HEIGHT}
        wordWrap={false}
      >
        {({ Cell, Column, HeaderCell }) => (
          <>
            <Column
              flexGrow={simulation?.budget?.type === TypeBudget.COMPTE_ADMINISTRATIF ? 4 : 5}
              fullText
              verticalAlign="middle"
            >
              <HeaderCell>Ligne budgétaire</HeaderCell>
              <Cell dataKey="libelle">
                {(rowData) => renderLibelleRow(rowData, expandedRowKeys, feuilleSaisie)}
              </Cell>
            </Column>

            <Column
              align="right"
              flexGrow={1}
              verticalAlign="middle"
            >
              <HeaderCell>
                Budget total
                {' '}
                {simulation?.budget?.type === TypeBudget.BUDGET_PRIMITIF
                  ? simulation.exercice.anneeExercice - 1
                  : ''}
              </HeaderCell>
              <Cell dataKey="budget">
                {(rowData) => renderMontantText(rowData.budget)}
              </Cell>
            </Column>

            {simulation?.budget?.type === TypeBudget.COMPTE_ADMINISTRATIF ? (
              <>
                <Column
                  align="right"
                  flexGrow={1}
                  verticalAlign="center"
                >
                  <HeaderCell>
                    Liquidé
                  </HeaderCell>
                  <Cell dataKey="liquide_n">
                    {(rowData) => renderMontantText(rowData.liquide_n)}
                  </Cell>
                </Column>

                <Column
                  align="right"
                  flexGrow={1}
                  verticalAlign="center"
                >
                  <HeaderCell>
                    <Tooltip
                      trigger={<span>Solde</span>}
                      message="Solde = Budget - Liquidé"
                      type="information"
                    />
                  </HeaderCell>
                  <Cell dataKey="solde">
                    {(rowData) => renderMontantText(rowData.solde)}
                  </Cell>
                </Column>

                <Column
                  align="right"
                  flexGrow={1}
                  verticalAlign="center"
                >
                  <HeaderCell>
                    Engagé
                  </HeaderCell>
                  <Cell dataKey="engage_n">
                    {(rowData) => renderMontantText(rowData.engage_n)}
                  </Cell>
                </Column>
              </>
            ) : (
              <Column
                align="right"
                flexGrow={1}
                verticalAlign="center"
              >
                <HeaderCell>
                  Réalisé total
                  {' '}
                  {simulation?.budget?.type === TypeBudget.BUDGET_PRIMITIF
                    ? simulation.exercice.anneeExercice - 1
                    : ''}
                </HeaderCell>
                <Cell dataKey="realise">
                  {(rowData) => renderMontantText(rowData.realise)}
                </Cell>
              </Column>
            )}

            {(feuilleSaisie.isCreditReporteShown && shouldAddReport(simulation, feuilleSaisie)) && (
              <Column
                align="right"
                flexGrow={1}
                verticalAlign="middle"
              >
                <HeaderCell>
                  <span className="bold">{`RAR ${simulation.exercice.anneeExercice - 1}`}</span>
                </HeaderCell>
                <Cell dataKey="montantReport">
                  {(rowData) => renderMontantReportCell(rowData)}
                </Cell>
              </Column>
            )}

            {simulation?.typePeriode === TypePeriode.DEMANDE && (
              <Column align="right" flexGrow={1} verticalAlign="middle">
                <HeaderCell>
                  Demandé
                </HeaderCell>
                <Cell dataKey="montantDemande">
                  {(rowData) => (!isUndefined(rowData?.nombre_montant)
                    ? renderMontantInput(rowData, TYPE_CHAMP.DEMANDE)
                    : renderMontantText(
                      getMontantImputation(rowData, TypePeriode.DEMANDE),
                      expandedRowKeys.includes(rowData.key),
                    )
                  )}
                </Cell>
              </Column>
            )}

            {[TypePeriode.PROPOSE, TypePeriode.VOTE]?.includes(simulation?.typePeriode) && (
              <Column align="right" flexGrow={1} verticalAlign="middle">
                <HeaderCell>
                  <span className={simulation.typePeriode === TypePeriode.PROPOSE && 'bold'}>
                    {`Proposé ${simulation.exercice.anneeExercice}`}
                  </span>
                </HeaderCell>
                <Cell dataKey="montantPropose">
                  {(rowData) => (
                    !isUndefined(rowData?.nombre_montant) && simulation?.typePeriode === TypePeriode.PROPOSE
                      ? renderMontantInput(rowData, TYPE_CHAMP.PROPOSE)
                      : renderMontantText(
                        getMontantImputation(rowData, TypePeriode.PROPOSE),
                        simulation.typePeriode === TypePeriode.PROPOSE && expandedRowKeys.includes(rowData.key),
                      )
                  )}
                </Cell>
              </Column>
            )}

            {simulation?.typePeriode === TypePeriode.VOTE && (
              <Column align="right" flexGrow={1} verticalAlign="middle">
                <HeaderCell>
                  <span className="bold">{`Voté ${simulation.exercice.anneeExercice}`}</span>
                </HeaderCell>
                <Cell dataKey="montantVote">
                  {(rowData) => (!isUndefined(rowData?.nombre_montant)
                    ? renderMontantInput(rowData, TYPE_CHAMP.VOTE)
                    : renderMontantText(
                      getMontantImputation(rowData, TypePeriode.VOTE),
                      expandedRowKeys.includes(rowData.key),
                    )
                  )}
                </Cell>
              </Column>
            )}

            {simulation?.typePeriode === TypePeriode.CA_CFU && (
              <Column align="right" flexGrow={1} verticalAlign="middle">
                <HeaderCell>
                  <span className="bold">Reste à réaliser</span>
                </HeaderCell>
                <Cell dataKey="montantCaCfu">
                  {(rowData) => (!isUndefined(rowData?.nombre_montant)
                    ? renderMontantInput(rowData, TYPE_CHAMP.CA)
                    : renderMontantText(
                      getMontantImputation(rowData, TypePeriode.CA_CFU),
                      expandedRowKeys.includes(rowData.key),
                    )
                  )}
                </Cell>
              </Column>
            )}

            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell>Commentaires</HeaderCell>
              <Cell className="commentaireCell" dataKey="commentaire">
                {(rowData) => !isUndefined(rowData?.nombre_montant)
                  && renderCommentaireCell(rowData)}
              </Cell>
            </Column>
            <Column>
              { /* @ts-expect-error */ }
              <HeaderCell />
              <Cell className="outilCell" verticalAlign="center">
                {renderOutilCell}
              </Cell>
            </Column>
          </>
        )}
      </Table>
      <CalculPropositionModal
        data={outilModalData}
        onClose={handleOutilModalClose}
        onValidate={handleOutilModalValidate}
        open={outilModalOpen}
        simulation={simulation}
      />
    </>
  );
};

export default ImputationTable;
