import React from 'react';
import { SemanticWIDTHS, type IconJVSProps } from '@jvs-group/jvs-mairistem-composants';

/**
 * Dans cette classe, nous avons certaines fonctions qui sont
 * déclarées différement car comme nous faisons de la destructuration
 * le this n'est pas bien bind. Avec cette syntaxe, ça fonctionne
 */
class Panel {
  code: string;

  size: SemanticWIDTHS;

  icon: IconJVSProps;

  isVisible: boolean;

  libelle: string;

  tooltip: string;

  /**
   * Permet de retourner le composant
   * @param data Données de la simulation
   * @returns Le composant
   */
  getComponent = function getComponent(
    _data: any,
  ): React.ReactElement {
    return null;
  };

  getLibelle = function getLibelle(_sens: string) : string {
    return this.libelle;
  };
}

export default Panel;
