import React from 'react';
import {
  Button,
  Combobox,
  Dimmer,
  Form,
  Icon,
  Loader,
  Modal,
  Segment,
} from '@jvs-group/jvs-mairistem-composants';
import { isNil, orderBy } from 'lodash';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import type { OutilModalData } from '../../../Simulation/components/ImputationTable';
import CalculPropositionModalPopup from './CalculPropositionModalPopup';
import type RegleCalcul from '../../../Simulation/interfaces/regleCalcul';
import type Simulation from '../../../Simulation/interfaces/simulation';
import CalculPropositionModalOperation from './CalculPropositionModalOperation';
import CalculPropositionModalTable from './CalculPropositionModalTable';
import CalculPropositionModalOptions from './CalculPropositionModalOptions';
import { TreeSymbol, getRowFilters, getSymbol } from '../../../../utils/treeView';
import type { TreeRow } from '../../../FeuilleSaisie/interfaces/treeRow';
import { TypeArrondiRegleCalcul } from '../../../Simulation/enums/typeArrondiRegleCalcul';
import { getReglesCalcul } from '../../../Simulation/utils/regleCalcul';
import { getDetailCalculImputations } from '../../../Simulation/utils/simulation';
import type DetailCalcul from '../../interfaces/DetailCalcul';
import './CalculPropositionModal.less';

const MAX_LIGNES_DETAIL = 8;

const getRegleCalculOption = (reglesCalcul: RegleCalcul[]) => orderBy(
  reglesCalcul,
  ['defaut', 'libelle'],
  ['desc', 'asc'],
)?.map((regleCalcul) => ({
  key: regleCalcul?.identifiant,
  value: regleCalcul,
  text: (
    <span className="regleCalculOption">
      {regleCalcul?.defaut ? (
        <Icon
          iconSet="Lucide"
          name="FolderDot"
          size={20}
        />
      ) : (
        <Icon
          iconSet="Lucide"
          name="PencilLine"
          size={20}
        />
      )}
      {regleCalcul?.libelle}
    </span>
  ),
}));

const getImputationText = (data: OutilModalData): string => {
  let parent: TreeRow = getSymbol(data?.rowData, TreeSymbol.PARENT);
  // Si nous avons plusieurs elements de l'imputation.
  if (parent) {
    const codes = [data?.rowData?.[data?.rowData?.typesRegroupement?.[0]?.codeColumnName]];
    // On remonte à chaque parent, et on récupère son code
    while (parent) {
      codes.push(parent[parent?.typesRegroupement?.[0]?.codeColumnName]);
      parent = getSymbol(parent, TreeSymbol.PARENT);
    }

    return codes.join(' | ');
  }

  return `${data?.rowData?.typesRegroupement[0]?.libelle} 
    ${data?.rowData?.[data?.rowData?.typesRegroupement[0]?.codeColumnName]}`;
};

export interface MajorationMinorationValues {
  forfaitaire: number;
  pourcentage: number;
}

interface CalculPropositionModalProps {
  data: OutilModalData;
  onClose: () => void;
  onValidate: (data: TreeRow, regleCalcul: RegleCalcul) => void;
  open: boolean;
  simulation: Simulation;
}

const CalculPropositionModal = ({
  data,
  onClose,
  onValidate,
  open,
  simulation,
}: CalculPropositionModalProps) => {
  const [calculLoading, setCalculLoading] = React.useState<boolean>(false);
  const [detailCalcul, setDetailCalcul] = React.useState<DetailCalcul>();
  const [majorationMinorationValues, setMajorationMinorationValues] = React.useState<MajorationMinorationValues>({
    forfaitaire: 0,
    pourcentage: 0,
  });
  const [modalLoading, setModalLoading] = React.useState<boolean>(false);
  const [reglesCalcul, setReglesCalcul] = React.useState<RegleCalcul[]>([]);
  const [selectedRegleCalcul, setSelectedRegleCalcul] = React.useState<RegleCalcul>();
  const [typeArrondi, setTypeArrondi] = React.useState<TypeArrondiRegleCalcul>(TypeArrondiRegleCalcul.AUCUN);

  const fetchReglesCalcul = async () => {
    // On récupère les règles de calcul
    try {
      setModalLoading(true);
      const { data } = await getReglesCalcul({
        all: true,
        withLignes: true,
      });
      setReglesCalcul(data);
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors de la récupération des règles de calcul'));
      onClose?.();
    } finally {
      setModalLoading(false);
    }
  };

  React.useEffect(() => {
    if (open) fetchReglesCalcul();
  }, [open]);

  // La règle par défaut doit être la reprise du budget total de l'exercice précedent
  React.useEffect(() => {
    setSelectedRegleCalcul(reglesCalcul?.find((regleCalcul) => regleCalcul.identifiant === 1));
  }, [reglesCalcul]);

  const resetOptions = () => {
    setTypeArrondi(TypeArrondiRegleCalcul.AUCUN);
    setMajorationMinorationValues({ forfaitaire: 0, pourcentage: 0 });
  };

  const fetchDetailCalculImputations = async () => {
    if (!isNil(selectedRegleCalcul)) {
      setDetailCalcul(await getDetailCalculImputations(
        simulation?.identifiant,
        getRowFilters(data.rowData, data.filters, data.feuilleSaisie),
        data.feuilleSaisie.nodes?.flat(),
        {
          identifiantRegleCalcul: selectedRegleCalcul.identifiant,
          typeArrondi,
          pourcentageMajoration: majorationMinorationValues.pourcentage,
          montantForfaitaire: majorationMinorationValues.forfaitaire,
          codeFeuille: data.feuilleSaisie.code,
        },
      ));
    }
  };

  React.useEffect(() => {
    fetchDetailCalculImputations();
  }, [selectedRegleCalcul, typeArrondi, majorationMinorationValues]);

  const handleClose = () => {
    resetOptions();
    return onClose?.();
  };

  const handleValidate = async () => {
    try {
      setCalculLoading(true);
      await onValidate?.(
        data.rowData,
        {
          ...selectedRegleCalcul,
          ...majorationMinorationValues,
          typeArrondi,
        },
      );

      resetOptions();
    } catch (e) {
      toast.error(getErrorMessage(e, "Erreur lors de l'application du calcul aux propositions"));
    } finally {
      setCalculLoading(false);
    }
  };

  const handleChangeInput = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>, value: number) => {
    setMajorationMinorationValues((old) => ({
      ...old,
      [currentTarget?.name]: value,
    }));
  };

  // Application des valeurs par défaut
  const handleChangeRegleCalcul = (
    e: React.MouseEvent,
    { value }: { value: RegleCalcul },
  ) => {
    setMajorationMinorationValues({ forfaitaire: value.forfaitaire, pourcentage: value.pourcentage });
    setSelectedRegleCalcul(value);
    setTypeArrondi(value.typeArrondi);
  };

  const handleChangeTypeArrondi = (e: React.ChangeEvent<HTMLSelectElement>, { value }: { value: number }) => {
    setTypeArrondi(value);
  };

  return (
    <Modal
      closeIcon
      onClose={handleClose}
      open={open}
    >
      <Modal.Header content={`Calculer les propositions pour les lignes budgétaires [${getImputationText(data)}]`} />
      <Modal.Content className="calculPropositionModal">
        { modalLoading ? (
          <Dimmer active inverted>
            <Loader size="massive" />
          </Dimmer>
        ) : (
          <Form>
            <Form.Group className="regleCalculGroup">
              <Form.Field
                control={Combobox}
                data-testid="regleCalculCombobox"
                label="Sélection d'une règle de calcul"
                // @ts-expect-error
                menuPortalTarget={document.body}
                onChange={handleChangeRegleCalcul}
                options={getRegleCalculOption(reglesCalcul)}
                selection
                value={selectedRegleCalcul}
                width={15}
              />
              <Form.Field
                // @ts-expect-error
                anneeExercice={simulation?.exercice?.anneeExercice}
                control={CalculPropositionModalPopup}
                label=" "
                regleCalcul={selectedRegleCalcul}
                width={1}
              />
            </Form.Group>
            Options
            <Segment>
              <CalculPropositionModalOptions
                majorationMinorationValues={majorationMinorationValues}
                onChangeInput={handleChangeInput}
                onChangeTypeArrondi={handleChangeTypeArrondi}
                typeArrondi={typeArrondi}
              />
            </Segment>
            Exemple pour la première ligne budgétaire
            <Segment>
              {selectedRegleCalcul?.lignes?.length < MAX_LIGNES_DETAIL && (
                <CalculPropositionModalTable
                  anneeExercice={simulation?.exercice?.anneeExercice}
                  detailCalcul={detailCalcul}
                  regleCalcul={selectedRegleCalcul}
                />
              )}

              <CalculPropositionModalOperation
                anneeExercice={simulation?.exercice?.anneeExercice}
                majorationMinorationValues={majorationMinorationValues}
                detailCalcul={detailCalcul}
                regleCalcul={selectedRegleCalcul}
              />
            </Segment>
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Annuler"
          data-testid="closeButton"
          icon="x"
          onClick={handleClose}
          size="tiny"
        />
        <Button
          content="Appliquer le calcul"
          data-testid="confirmButton"
          disabled={calculLoading || modalLoading}
          loading={calculLoading}
          onClick={handleValidate}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default CalculPropositionModal;
