import React from 'react';
import { ListeTable, ListeTableContainer } from '@jvs-group/jvs-mairistem-liste';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import { Button } from '@jvs-group/jvs-mairistem-composants';
import RegleCalcul from '../../../../Simulation/interfaces/regleCalcul';
import RegleCalculModal from '../Modal/RegleCalculModal';
import RegleCalculActions from '../enums/RegleCalculActions';
import RegleCalculListRow from './RegleCalculListRow';
import { getReglesCalcul } from '../../../../Simulation/utils/regleCalcul';

const RegleCalculList = () => {
  const [action, setAction] = React.useState<RegleCalculActions>();
  const [currentRegleCalcul, setCurrentRegleCalcul] = React.useState<RegleCalcul>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [reglesCalcul, setReglesCalcul] = React.useState<RegleCalcul[]>([]);

  const fetchReglesCalcul = async () => {
    try {
      setLoading(true);
      const { data } = await getReglesCalcul({
        all: true,
        defaut: false,
        withLignes: true,
      });
      setReglesCalcul(data.map((regleCalcul) => ({
        ...regleCalcul,
        deletedLignes: [],
      })));
    } catch (e) {
      toast.error(getErrorMessage(e, 'Impossible de récuperer les règles de calcul'));
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchReglesCalcul();
  }, []);

  const handleAddRegleCalcul = () => {
    setAction(RegleCalculActions.ADD);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleDeleteRegleCalcul = (regleCalcul: RegleCalcul) => {
    setCurrentRegleCalcul(regleCalcul);
    setAction(RegleCalculActions.DELETE);
    setOpen(true);
  };

  const handleEditRegleCalcul = (regleCalcul: RegleCalcul) => {
    setCurrentRegleCalcul(regleCalcul);
    setAction(RegleCalculActions.EDIT);
    setOpen(true);
  };

  const handleValidate = (regleCalcul: RegleCalcul) => {
    setOpen(false);
    switch (action) {
      case RegleCalculActions.ADD:
        setReglesCalcul((old) => [
          ...old,
          regleCalcul,
        ]);
        break;
      case RegleCalculActions.DELETE:
        setReglesCalcul((old) => old.filter((regle) => regle.identifiant !== regleCalcul.identifiant));
        break;
      case RegleCalculActions.EDIT:
        setReglesCalcul((old) => old.map(
          (regle) => ((regle.identifiant === regleCalcul.identifiant) ? regleCalcul : regle),
        ));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Button
        basic
        content="Créer une règle personnalisée"
        data-testid="createRegleCalculButton"
        floated="right"
        icon="plus"
        onClick={handleAddRegleCalcul}
      />
      <ListeTableContainer items={reglesCalcul}>
        <ListeTable loading={loading}>
          { /* @ts-expect-error */ }
          <ListeTable.Header>
            <ListeTable.Column name="libelle" width={16}>Libellé</ListeTable.Column>
          </ListeTable.Header>
          { (regleCalcul: RegleCalcul) => (
            <RegleCalculListRow
              onDelete={handleDeleteRegleCalcul}
              onEdit={handleEditRegleCalcul}
              regleCalcul={regleCalcul}
            />
          ) }
        </ListeTable>
      </ListeTableContainer>
      <RegleCalculModal
        action={action}
        onClose={handleClose}
        onValidate={handleValidate}
        open={open}
        regleCalcul={currentRegleCalcul}
      />
    </>
  );
};

export default RegleCalculList;
