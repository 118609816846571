import React from 'react';
import BaseOutil from './BaseOutil';
import CalculVirementCreditModal from '../components/CalculVirementCreditModal';
import type Simulation from '../../Simulation/interfaces/simulation';
import type UserRole from '../../../constants/userRole';
import TypeBudget from '../../Simulation/enums/typeBudget';

class CalculVirementCredit extends BaseOutil {
  constructor() {
    super();
    this.code = 'CALC_VIR_CRED';
    this.icon = {
      iconSet: 'Lucide',
      name: 'Scale',
    };
  }

  title = 'Calculer les virements de crédits entre sections (021 - 023)';

  isEnabled(userRole: UserRole, simulation: Simulation) {
    return super.isEnabled(userRole, simulation)
      && !simulation?.transfertCpta
      && simulation?.budget?.type !== TypeBudget.COMPTE_ADMINISTRATIF;
  }

  renderModal(
    simulation: Simulation,
    onClose: () => void,
    onValidate: (data: unknown) => void,
    open: boolean,
  ) {
    return (
      <CalculVirementCreditModal
        onClose={onClose}
        onValidate={onValidate}
        open={open}
        simulation={simulation}
      />
    );
  }
}

export default CalculVirementCredit;
