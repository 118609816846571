enum TypeControleCoherence {
  CHAPITRE_SANS_EXECUTION_BUDGETAIRE = 'CSEB',
  OPERATIONS_CESSION = 'OC',
  OPERATIONS_ORDRE_INTERIEUR_SECTION_FONCTIONNEMENT = 'OOISF',
  OPERATIONS_ORDRE_PATRIMONIALES = 'OOP',
  OPERATIONS_ORDRE_TRANSFERT_SECTION_FONCTIONNEMENT = 'OOTSF',
  OPERATIONS_ORDRE_TRANSFERT_SECTION_INVESTISSEMENT = 'OOTSI',
  PLAFOND_DEPENSES_IMPREVUES_FONCTIONNEMENT = 'PDIF',
  PLAFOND_DEPENSES_IMPREVUES_INVESTISSEMENT = 'PDII',
  REEL = 'R',
  SECTION_FONCTIONNEMENT = 'SF',
  SECTION_INVESTISSEMENT = 'SI',
  VIREMENT_SECTION_FONCTIONNEMENT = 'VSF',
}

export default TypeControleCoherence;
