/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Button,
  Modal,
} from '@jvs-group/jvs-mairistem-composants';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import type OutilModalProps from '../interfaces/OutilModalProps';
import { annulationTransfertComptabilite, transfertComptabilite } from '../utils/outil';
import type Simulation from '../../Simulation/interfaces/simulation';
import TypeBudget from '../../Simulation/enums/typeBudget';

const renderHeaderText = (simulation: Simulation): string => {
  if (simulation?.transfertCpta) {
    switch (simulation?.budget?.type) {
      case TypeBudget.BUDGET_PRIMITIF:
        return 'Ne plus rendre exécutoire le budget primitif';
      case TypeBudget.BUDGET_SUPPLEMENTAIRE:
        return 'Ne plus rendre exécutoire le budget supplémentaire';
      case TypeBudget.COMPTE_ADMINISTRATIF:
        return 'Dévalider les restes à réaliser';
      case TypeBudget.DECISION_MODIFICATIVE:
        return 'Ne plus rendre exécutoire la décision modificative';
      case TypeBudget.VIREMENT_INTERNE:
        return 'Dévalider';
      default:
        return null;
    }
  } else {
    switch (simulation?.budget?.type) {
      case TypeBudget.BUDGET_PRIMITIF:
        return 'Rendre exécutoire le budget primitif ';
      case TypeBudget.BUDGET_SUPPLEMENTAIRE:
        return 'Rendre exécutoire le budget supplémentaire';
      case TypeBudget.COMPTE_ADMINISTRATIF:
        return 'Valider les restes à réaliser';
      case TypeBudget.DECISION_MODIFICATIVE:
        return 'Rendre exécutoire la décision modificative';
      case TypeBudget.VIREMENT_INTERNE:
        return 'Valider';
      default:
        return null;
    }
  }
};

const renderContentText = (simulation: Simulation): React.ReactNode => {
  if (simulation?.transfertCpta) {
    switch (simulation?.budget?.type) {
      case TypeBudget.BUDGET_PRIMITIF:
        return (
          <>
            Le budget primitif va perdre son caractère exécutoire.
            <br />
            Les montants votés n'alimenteront plus les crédits disponibles pour l'exécution budgétaire.
          </>
        );
      case TypeBudget.BUDGET_SUPPLEMENTAIRE:
        return (
          <>
            Le budget supplémentaire va perdre son caractère exécutoire.
            <br />
            Les montants votés n'alimenteront plus les crédits disponibles pour l'exécution budgétaire.
          </>
        );
      case TypeBudget.COMPTE_ADMINISTRATIF:
        return (
          <>
            Vos restes à réaliser vont être dévalidés.
            <br />
            Ils ne seront plus récupérables dans la préparation de vos budgets N+1
          </>
        );
      case TypeBudget.DECISION_MODIFICATIVE:
        return (
          <>
            Cette décision va perdre son caractère exécutoire.
            <br />
            Les montants votés n'alimenteront plus les crédits disponibles pour l'exécution budgétaire.
          </>
        );
      case TypeBudget.VIREMENT_INTERNE:
        return (
          <>
            Les montants vont être dévalidés.
            <br />
            Ils n'alimenteront plus les crédits disponibles pour l'exécution budgétaire.
          </>
        );
      default:
        return null;
    }
  } else {
    switch (simulation?.budget?.type) {
      case TypeBudget.BUDGET_PRIMITIF:
        return (
          <>
            Le budget primitif va être rendu exécutoire.
            <br />
            Les montants votés alimentent les crédits disponibles pour l'exécution budgétaire.
          </>
        );
      case TypeBudget.BUDGET_SUPPLEMENTAIRE:
        return (
          <>
            Le budget supplémentaire va être rendu exécutoire.
            <br />
            Les montants votés alimentent les crédits disponibles pour l'exécution budgétaire.
          </>
        );
      case TypeBudget.COMPTE_ADMINISTRATIF:
        return (
          <>
            Vos restes à réaliser vont être validés.
            <br />
            Ils seront récupérables dans la préparation de vos budgets N+1
          </>
        );
      case TypeBudget.DECISION_MODIFICATIVE:
        return (
          <>
            Cette décision va être rendue exécutoire.
            <br />
            Les montants votés alimentent les crédits disponibles pour l'exécution budgétaire.
          </>
        );
      case TypeBudget.VIREMENT_INTERNE:
        return (
          <>
            Les montants vont être validés.
            <br />
            Ils alimentent les crédits disponibles pour l'exécution budgétaire.
          </>
        );
      default:
        return null;
    }
  }
};

const TransfertComptaModal = ({
  onClose,
  onValidate,
  open,
  simulation,
}: OutilModalProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleAnnulation = async () => {
    try {
      setLoading(true);
      await annulationTransfertComptabilite(simulation?.identifiant);
      onValidate?.();
    } catch (e) {
      toast.error(getErrorMessage(e, "Erreur lors de l'annulation du transfert en comptabilité"));
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => onClose?.();

  const handleTransfert = async () => {
    try {
      setLoading(true);
      await transfertComptabilite(simulation?.identifiant);
      onValidate?.();
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors du transfert en comptabilité'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      closeIcon
      onClose={handleClose}
      open={open}
    >
      <Modal.Header content={renderHeaderText(simulation)} />
      <Modal.Content>{renderContentText(simulation)}</Modal.Content>
      <Modal.Actions>
        <Button
          basic
          content="Annuler"
          data-testid="closeButton"
          icon="times"
          onClick={handleClose}
          size="tiny"
        />
        <Button
          content="Oui"
          data-testid="confirmButton"
          disabled={loading}
          icon="check"
          loading={loading}
          onClick={simulation?.transfertCpta ? handleAnnulation : handleTransfert}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default TransfertComptaModal;
