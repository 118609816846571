import React from 'react';
import { request } from '@jvs-group/jvs-mairistem-store';
import { formatMontant, type TemplateRequestGet } from '@jvs-group/jvs-mairistem-finances-utils';
import type MontantImputation from '../interfaces/montantImputation';

export const getMontantsSimulation = (
  identifiantImputationSimulation: number,
// eslint-disable-next-line max-len
): Promise<TemplateRequestGet<MontantImputation>> => request.get(`/api/finances/simulation/montantSimulation?all=true&withEtiquettes=true&identifiantImputationSimulation=${identifiantImputationSimulation}`);

export const createMontant = (
  data: Partial<MontantImputation>,
) : Promise<MontantImputation> => request.post('/api/finances/simulation/montantSimulation', data);

export const updateMontant = (
  identifiantMontant: number,
  data: Partial<MontantImputation>,
) : Promise<MontantImputation> => request.put(`/api/finances/simulation/montantSimulation/${identifiantMontant}`, data);

export const deleteMontant = (identifiantMontant: number) => request.delete(
  `/api/finances/simulation/montantSimulation/${identifiantMontant}`,
);

export const renderMontantText = (montant: number, isBold = false) => (
  <span className={['montantText', ...isBold ? ['bold'] : []].join(' ')}>{formatMontant(montant ?? 0)}</span>
);
