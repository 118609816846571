import React from 'react';
import { Icon, Popup } from '@jvs-group/jvs-mairistem-composants';
import CalculPropositionModalPopupContent from './CalculPropositionModalPopupContent';
import type RegleCalcul from '../../../Simulation/interfaces/regleCalcul';
import './CalculPropositionModalPopup.less';

interface CalculPropositionModalPopupProps {
  anneeExercice: number;
  regleCalcul: RegleCalcul;
}

const CalculPropositionModalPopup = ({
  anneeExercice,
  regleCalcul,
}: CalculPropositionModalPopupProps) => (
  <Popup
    className="calculPropositionPopup"
    content={(
      <CalculPropositionModalPopupContent
        anneeExercice={anneeExercice}
        disabled
        lignesRegleCalcul={regleCalcul?.lignes}
        typeCalcul={regleCalcul?.typeCalcul}
      />
    )}
    data-testid="regleCalculPopup"
    offset={[8, 0]}
    on="click"
    position="bottom right"
    trigger={(
      <Icon
        cursor="pointer"
        data-testid="regleCalculIconPopup"
        iconSet="Lucide"
        name="Info"
      />
)}
    wide="very"
  />
);

export default CalculPropositionModalPopup;
