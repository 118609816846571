import { isNil } from 'lodash';

const getImputationLibelle = (
  sens: string,
  section: string,
  chapitre: string,
  article: string,
  operation?: string,
  fonction?: string,
  ventilation?: string,
  analytique?: string,

) : string => {
  // eslint-disable-next-line max-len
  let libelle = `${sens ?? ''} ${section ?? ''} ${chapitre ?? ''} ${article ?? ''} ${operation || ''} ${fonction || ''}`;

  if (!isNil(ventilation)) {
    libelle += ` | ${ventilation}`;
  }
  if (!isNil(analytique)) {
    libelle += ` | ${analytique}`;
  }

  return libelle;
};

export default getImputationLibelle;
