import React from 'react';
import TypeRegroupement from '../../constants/typeRegroupement';
import { TreeRow } from '../../interfaces/treeRow';
import BaseTypeRegroupement from './BaseTypeRegroupement';
import type BaseFeuilleSaisie from '../FeuilleSaisie/BaseFeuilleSaisie';

class SectionTypeRegroupement extends BaseTypeRegroupement {
  constructor() {
    super();
    this.code = TypeRegroupement.SECTION;
    this.codeColumnName = 'cha_section';
    this.icon = {
      iconSet: 'Lucide',
      name: 'CircleArrowRight',
    };
    this.libelle = 'Section';
  }

  getLibelle(data: TreeRow, _feuille: BaseFeuilleSaisie): string {
    if (data?.[this.codeColumnName] === 'I') {
      return 'Investissement';
    }

    if (data?.[this.codeColumnName] === 'F') {
      return 'Fonctionnement';
    }

    return '';
  }

  getTabLibelle(data: TreeRow): JSX.Element {
    return (<>{this.getLibelle(data, null)}</>);
  }
}

export default SectionTypeRegroupement;
