import React from 'react';
import type RegleCalcul from '../../../../Simulation/interfaces/regleCalcul';
import RegleCalculActions from '../enums/RegleCalculActions';
import RegleCalculModalDelete from './RegleCalculModalDelete';
import RegleCalculModalAdd from './RegleCalculModalAdd';
import RegleCalculModalEdit from './RegleCalculModalEdit';

interface RegleCalculModalProps {
  action: RegleCalculActions;
  onClose: () => void;
  onValidate: (regleCalcul: RegleCalcul) => void;
  open: boolean;
  regleCalcul?: RegleCalcul;
}

export type RegleCalculModalActionsProps = Omit<RegleCalculModalProps, 'action'>;

const RegleCalculModal = ({
  action,
  onClose,
  onValidate,
  open,
  regleCalcul,
}: RegleCalculModalProps) => (
  <>
    { action === RegleCalculActions.ADD && (
      <RegleCalculModalAdd
        onClose={onClose}
        onValidate={onValidate}
        open={open}
      />
    )}
    { action === RegleCalculActions.DELETE && (
      <RegleCalculModalDelete
        onClose={onClose}
        onValidate={onValidate}
        open={open}
        regleCalcul={regleCalcul}
      />
    )}
    { action === RegleCalculActions.EDIT && (
      <RegleCalculModalEdit
        onClose={onClose}
        onValidate={onValidate}
        open={open}
        regleCalcul={regleCalcul}
      />
    )}
  </>
);

export default RegleCalculModal;
