import React from 'react';
import { Accordion, Grid } from '@jvs-group/jvs-mairistem-composants';
import Outil from '../../Outils/components/Outil';
import UserRole from '../../../constants/userRole';
import type Simulation from '../interfaces/simulation';
import type BaseOutil from '../../Outils/classes/BaseOutil';
import RecuperationDonneeConnexe from '../../Outils/classes/RecuperationDonneeConnexe';
import ImportExportExcel from '../../Outils/classes/ImportExportExcel';
import CalculVirementCredit from '../../Outils/classes/CalculVirementCredit';
import ControleCoherence from '../../Outils/classes/ControleCoherence';
import RecopiePropositionEnVote from '../../Outils/classes/RecopiePropositionEnVote';
import RecopieReport from '../../Outils/classes/RecopieReport';
import TransfertCompta from '../../Outils/classes/TransfertCompta';
import CalculRAR from '../../Outils/classes/CalculRAR';

interface BoiteOutilProps {
  accordionActive: boolean;
  onRefresh: () => void;
  onToggleAccordion: () => void;
  simulation: Simulation;
  userRole: UserRole;
}

const BoiteOutil = ({
  accordionActive = false,
  onRefresh,
  onToggleAccordion,
  simulation,
  userRole = UserRole.NORMAL,
}: BoiteOutilProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedOutil, setSelectedOutil] = React.useState<BaseOutil>();

  const handleClickOutil = (outil: BaseOutil) => {
    setSelectedOutil(outil);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleValidate = () => {
    setOpen(false);
    onRefresh?.();
  };

  const outils: BaseOutil[] = [
    new RecopieReport(simulation),
    new RecuperationDonneeConnexe(),
    new ImportExportExcel(),
    new CalculVirementCredit(),
    new RecopiePropositionEnVote(simulation),
    new CalculRAR(),
    new ControleCoherence(simulation),
    new TransfertCompta(simulation),
  ];

  return (
    <>
      <Accordion className="fiche-panel-header segment">
        <Accordion.Title
          active={accordionActive}
          data-testid="accordionOutils"
          onClick={onToggleAccordion}
          content="BOÎTE À OUTILS"
        />
        <Accordion.Content active={accordionActive}>
          <Grid padded>
            <Grid.Row className="nowrap">
              { outils?.map((outil) => outil?.isEnabled(userRole, simulation) && (
                <Grid.Column data-testid={`outil${outil?.code}`} onClick={() => handleClickOutil(outil)} width={3}>
                  <Outil
                    icon={outil?.icon}
                    title={outil?.title}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        </Accordion.Content>
      </Accordion>
      { selectedOutil?.renderModal(
        simulation,
        handleClose,
        handleValidate,
        open,
      ) }
    </>
  );
};

export default BoiteOutil;
