import { PANEL_TYPE, PinnedPanel, TemplateRequestGet } from '@jvs-group/jvs-mairistem-finances-utils';
import { request } from '@jvs-group/jvs-mairistem-store';

export const getPinnedPanel = async (identifiantEntite: number) => {
  const url = '/api/finances/comptabilite/piecescomptables/pinnedPanel';
  const parameters = new URLSearchParams();
  parameters.append('type', PANEL_TYPE.SIMULATION.toString());
  parameters.append('identifiantEntite', identifiantEntite.toString());

  return request.get<unknown, TemplateRequestGet<PinnedPanel>>(`${url}?${parameters.toString()}`);
};

export const deletePinnedPanel = async (code: string, identifiantEntite: number) => {
  const parameters = new URLSearchParams();
  parameters.append('identifiantEntite', identifiantEntite.toString());
  parameters.append('code', code);
  parameters.append('type', PANEL_TYPE.SIMULATION.toString());

  const url = `/api/finances/comptabilite/piecescomptables/pinnedPanel?${parameters?.toString()}`;
  return request.delete(url);
};

export const addPinnedPanel = async (code: string, identifiantEntite: number) => {
  const url = '/api/finances/comptabilite/piecescomptables/pinnedPanel';
  return request.post(url, {
    identifiantEntite,
    code,
    type: PANEL_TYPE.SIMULATION,
  });
};
