import { request } from '@jvs-group/jvs-mairistem-store';
import { toast } from 'react-toastify';
import type UserService from '../interfaces/userService';
import UserRole from '../constants/userRole';

export const getUserService = async (): Promise<UserService> => {
  try {
    const data = await request.get<unknown, UserService>(
      '/api/finances/comptabilite/piecescomptables/usersService/getUserRole',
    );
    return data;
  } catch {
    toast.error("Erreur lors de la récupération du service de l'utilisateur");
  }

  return null;
};

export const getUserRole = async (): Promise<number> => {
  const service = await getUserService();

  if (service?.useAdmin) return UserRole.ADMIN;
  if (service?.useGestionnaire) return UserRole.GESTIONNAIRE;
  if (service?.usePilotage) return UserRole.CHARGE_PILOTAGE;

  return UserRole.NORMAL;
};
