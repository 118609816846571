import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import FamilleControleCoherence from '../../enums/FamilleControleCoherence';
import StatutControleCoherence from '../../enums/StatutControleCoherence';
import BaseControleCoherence from './BaseControleCoherence';
import TypeBudget from '../../../Simulation/enums/typeBudget';
import type MontantControleCoherence from '../../interfaces/MontantControleCoherence';

class EquilibreOperationOrdreTransfertFonctionnement extends BaseControleCoherence {
  constructor(montants: MontantControleCoherence) {
    super(montants);
    this.famille = FamilleControleCoherence.OPERATIONS_ORDRE;
  }

  getExplicatif(typeBudget: TypeBudget): string {
    const depenseFonctionnement042 = formatMontant(this.montants.depenseFonctionnement042, 2, true);
    const recetteInvestissement040 = formatMontant(this.montants.recetteInvestissement040, 2, true);

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        if (typeBudget === TypeBudget.COMPTE_ADMINISTRATIF) {
          return `Le total des dépenses de fonctionnement réalisées au chapitre 042 (${depenseFonctionnement042}) diffère des recettes d'investissement réalisées au 040 (${recetteInvestissement040}).`;
        }
        return `Le total des prévisions budgétaires inscrites en dépenses de fonctionnement au chapitre 042 (${depenseFonctionnement042}) diffère des recettes d'investissement inscrites au 040 (${recetteInvestissement040}).`;
      case StatutControleCoherence.OK:
        if (typeBudget === TypeBudget.COMPTE_ADMINISTRATIF) {
          return `Le total des dépenses de fonctionnement réalisées au chapitre 042 est égal aux recettes d'investissement réalisées au 040 (${recetteInvestissement040}).`;
        }
        return `Le total des prévisions budgétaires inscrites en dépenses de fonctionnement au chapitre 042 est égal aux recettes d'investissement inscrites au 040 (${recetteInvestissement040}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return 'Déséquilibre des opérations d’ordre de transfert de la section de fonctionnement vers la section d\'investissement';
      case StatutControleCoherence.OK:
        return 'Équilibre des opérations d’ordre de transfert de la section de fonctionnement vers la section d\'investissement';
      default:
        return '';
    }
  }

  getStatut(): number {
    const { depenseFonctionnement042, recetteInvestissement040 } = this.montants;

    if (depenseFonctionnement042 === recetteInvestissement040) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default EquilibreOperationOrdreTransfertFonctionnement;
