const TYPE_MONTANT_REGLE_CALCUL = {
  BUDGET_TOTAL: 0,
  BUDGET_TOTAL_N1: 1,
  BUDGET_TOTAL_N2: 2,
  BUDGET_PRIMITIF: 3,
  BUDGET_PRIMITIF_N1: 4,
  BUDGET_PRIMITIF_N2: 5,
  BUDGET_SUPPLEMENTAIRE: 6,
  BUDGET_SUPPLEMENTAIRE_N1: 7,
  BUDGET_SUPPLEMENTAIRE_N2: 8,
  RESTES_A_REALISER_N1: 9,
  DECISIONS_MODIFICATIVES: 10,
  DECISIONS_MODIFICATIVES_N1: 11,
  DECISIONS_MODIFICATIVES_N2: 12,
  REALISE: 13,
  REALISE_N1: 14,
  REALISE_N2: 15,
} as const;

export default TYPE_MONTANT_REGLE_CALCUL;
