export enum TypeArrondiRegleCalcul{
  AUCUN = 0,
  EURO = 1,
  DIZAINE = 2,
  CENTAINE = 3,
}

export const TYPE_ARRONDI_REGLE_CALCUL_OPTIONS = [
  {
    'data-testid': `typeArrondi${TypeArrondiRegleCalcul.AUCUN}`,
    key: TypeArrondiRegleCalcul.AUCUN,
    text: 'Sans arrondi',
    value: TypeArrondiRegleCalcul.AUCUN,
  },
  {
    'data-testid': `typeArrondi${TypeArrondiRegleCalcul.EURO}`,
    key: TypeArrondiRegleCalcul.EURO,
    text: "À l'euro",
    value: TypeArrondiRegleCalcul.EURO,
  },
  {
    'data-testid': `typeArrondi${TypeArrondiRegleCalcul.DIZAINE}`,
    key: TypeArrondiRegleCalcul.DIZAINE,
    text: "À la dizaine d'euro",
    value: TypeArrondiRegleCalcul.DIZAINE,
  },
  {
    'data-testid': `typeArrondi${TypeArrondiRegleCalcul.CENTAINE}`,
    key: TypeArrondiRegleCalcul.CENTAINE,
    text: "À la centaine d'euros",
    value: TypeArrondiRegleCalcul.CENTAINE,
  },
] as const;
