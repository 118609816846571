import FamilleControleCoherence from '../../enums/FamilleControleCoherence';
import type TypeBudget from '../../../Simulation/enums/typeBudget';
import StatutControleCoherence from '../../enums/StatutControleCoherence';
import type MontantControleCoherence from '../../interfaces/MontantControleCoherence';

class BaseControleCoherence {
  famille: FamilleControleCoherence;

  montants: MontantControleCoherence;

  statut: StatutControleCoherence;

  constructor(montants: MontantControleCoherence) {
    this.montants = montants;
    this.statut = this.getStatut();
  }

  getExplicatif(_typeBudget: TypeBudget): string { return ''; }

  getFamille(): string {
    switch (this.famille) {
      case FamilleControleCoherence.EQUILIBRE_BUDGETAIRE:
        return 'Equilibre budgétaire';
      case FamilleControleCoherence.OPERATIONS_ORDRE:
        return "Opérations d'ordre";
      case FamilleControleCoherence.AUTRES:
        return 'Autres règles';
      default:
        return '';
    }
  }

  getLibelle(): string { return ''; }

  getLibelleWithStatut(): string {
    let prefix = '';

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        prefix = 'Anomalie';
        break;
      case StatutControleCoherence.WARNING:
        prefix = 'A vérifier';
        break;
      case StatutControleCoherence.OK:
        prefix = 'Contrôle OK';
        break;
      default:
        break;
    }

    return `${prefix} : ${this.getLibelle()}`;
  }

  getStatut(): StatutControleCoherence { return StatutControleCoherence.ERROR; }
}

export default BaseControleCoherence;
