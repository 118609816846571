import React from 'react';
import { Button, Modal } from '@jvs-group/jvs-mairistem-composants';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import { deleteRegleCalcul } from '../../../../Simulation/utils/regleCalcul';
import type { RegleCalculModalActionsProps } from './RegleCalculModal';

const RegleCalculModalDelete = ({
  onClose,
  onValidate,
  open,
  regleCalcul,
}: RegleCalculModalActionsProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteRegleCalcul(regleCalcul.identifiant);
      onValidate(regleCalcul);
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors de la suppression de la règle de calcul'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      closeIcon
      onClose={onClose}
      open={open}
    >
      <Modal.Header content={`Suppression de la règle de calcul: ${regleCalcul.libelle}`} />
      <Modal.Content>
        Voulez vous vraiment modifier cette règle de calcul ?
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Annuler"
          data-testid="closeButton"
          icon="x"
          onClick={onClose}
          size="tiny"
        />
        <Button
          content="Supprimer"
          data-testid="deleteButton"
          loading={loading}
          negative
          onClick={handleDelete}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default RegleCalculModalDelete;
