import TypeRegroupement from '../../constants/typeRegroupement';
import BaseTypeRegroupement from './BaseTypeRegroupement';

class FonctionTypeRegroupement extends BaseTypeRegroupement {
  constructor() {
    super();
    this.code = TypeRegroupement.FONCTION;
    this.codeColumnName = 'fon_code';
    this.libelleColumnName = 'fon_libelle';
    this.icon = {
      iconSet: 'Lucide',
      name: 'CircleArrowRight',
    };
    this.libelle = 'Fonction';
  }
}

export default FonctionTypeRegroupement;
