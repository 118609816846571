import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import FamilleControleCoherence from '../../enums/FamilleControleCoherence';
import StatutControleCoherence from '../../enums/StatutControleCoherence';
import BaseControleCoherence from './BaseControleCoherence';
import type MontantControleCoherence from '../../interfaces/MontantControleCoherence';

class EquilibreVirementSectionFonctionnement extends BaseControleCoherence {
  constructor(montants: MontantControleCoherence) {
    super(montants);
    this.famille = FamilleControleCoherence.OPERATIONS_ORDRE;
  }

  getExplicatif(): string {
    const depenseFonctionnement023 = formatMontant(this.montants.depenseFonctionnement023, 2, true);
    const recetteInvestissement021 = formatMontant(this.montants.recetteInvestissement021, 2, true);

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return `Le total des prévisions budgétaires inscrites en dépenses de fonctionnement au chapitre-article 023 (${depenseFonctionnement023}) diffère des recettes d'investissement inscrites au 021 (${recetteInvestissement021}).`;
      case StatutControleCoherence.OK:
        return `Le total des prévisions budgétaires inscrites en dépenses de fonctionnement au chapitre-article 023 est égal aux recettes d'investissement inscrites au 021 (${recetteInvestissement021}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return 'Déséquilibre du virement de la section de fonctionnement à la section d’investissement';
      case StatutControleCoherence.OK:
        return 'Équilibre du virement de la section de fonctionnement à la section d’investissement';
      default:
        return '';
    }
  }

  getStatut(): number {
    const { depenseFonctionnement023, recetteInvestissement021 } = this.montants;

    if (depenseFonctionnement023 === recetteInvestissement021) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default EquilibreVirementSectionFonctionnement;
