import React from 'react';
import {
  Button,
  Dimmer,
  Loader,
  Modal,
} from '@jvs-group/jvs-mairistem-composants';
import { Table } from 'rsuite-table';
import { uniqueId } from 'lodash';
import { formatMontant, getLibelleImputation } from '@jvs-group/jvs-mairistem-finances-utils';
import { importEmprunt, upsertMontants } from '../../utils/outil';
import type Simulation from '../../../Simulation/interfaces/simulation';
import ROW_HEIGHT from '../../../FeuilleSaisie/constants/tableProps';
import { renderTreeToggle } from '../../../../utils/treeView';

interface ImportModalProps {
  simulation: Simulation;
  onClose: () => void;
  onValidate: () => void;
}

interface EmpruntData {
  key: string;
  libelle: string;
  libelleDetail ?: string;
  children ?: EmpruntData[];
  montant: number;
  montantImputationComptable: number;
  montantProposition: number;
  identifiantImputation?: number;
  prop?: number;
}

enum ModeCalcul {
  SUBSTITUER = 0,
  CUMULER = 1,
}

const ImportEmprunt = ({
  simulation,
  onClose,
  onValidate,
}: ImportModalProps) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [loadingSave, setLoadingSave] = React.useState<boolean>(false);
  const [emprunts, setEmprunts] = React.useState<EmpruntData[]>([]);
  const [modeCalcul, setModeCalcul] = React.useState<ModeCalcul>(ModeCalcul.SUBSTITUER);

  const handleToggleModeCalcul = () => {
    setModeCalcul(modeCalcul === ModeCalcul.SUBSTITUER ? ModeCalcul.CUMULER : ModeCalcul.SUBSTITUER);
  };

  const handleLoadImportEmprunt = async () => {
    try {
      const emprunts = await importEmprunt(simulation?.identifiant);
      const article1641 : EmpruntData = {
        key: '1641',
        libelle: 'Remboursement du capital de la dette:',
        libelleDetail: 'dépense au compte 1641',
        children: [],
        montant: 0,
        montantImputationComptable: 0,
        montantProposition: 0,
      };
      const article66111 : EmpruntData = {
        key: '66111',
        libelle: 'Remboursement des intérêts de la dette:',
        libelleDetail: 'dépense au compte 66111',
        children: [],
        montant: 0,
        montantImputationComptable: 0,
        montantProposition: 0,
      };
      const article66112 : EmpruntData = {
        key: 'icne',
        libelle: 'ICNE: dépense au compte 66112',
        libelleDetail: 'dépense au compte 66112',
        children: [],
        montant: 0,
        montantImputationComptable: 0,
        montantProposition: 0,
      };

      emprunts?.forEach((emprunt) => {
        const e = {
          key: uniqueId(),
          libelle: getLibelleImputation(
            emprunt.sens,
            emprunt.section,
            emprunt.chapitre,
            emprunt.article,
            emprunt?.operation,
            emprunt?.fonction,
            emprunt?.ventilation,
            emprunt?.analytique,
          ),
          montant: emprunt.montant,
          montantImputationComptable: emprunt.montantImputationComptable,
          montantProposition: emprunt.montantProposition,
          identifiantImputation: emprunt.identifiantImputation,
        };

        if (emprunt?.article === '1641') {
          article1641.children.push(e);
          article1641.montant += emprunt.montant;
          article1641.montantImputationComptable += emprunt.montantImputationComptable;
          article1641.montantProposition += emprunt.montantProposition;
        } else if (emprunt?.article === '66111') {
          article66111.children.push(e);
          article66111.montant += emprunt.montant;
          article66111.montantImputationComptable += emprunt.montantImputationComptable;
          article66111.montantProposition += emprunt.montantProposition;
        } else if (emprunt?.article === '66112') {
          article66112.children.push(e);
          article66112.montant += emprunt.montant;
          article66112.montantImputationComptable += emprunt.montantImputationComptable;
          article66112.montantProposition += emprunt.montantProposition;
        }
      });

      setEmprunts([
        article1641,
        article66111,
        article66112,
      ]);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleLoadImportEmprunt();
  }, []);

  if (loading) {
    return (
      <div className="import">
        <Dimmer active inverted>
          <Loader size="massive" />
        </Dimmer>
      </div>
    );
  }

  const renderLibelleRow = (data) => (
    <div>
      <div>{data.libelle}</div>
      <div>{data.libelleDetail}</div>
    </div>
  );

  const renderPropositionAppplique = (rowData : EmpruntData) => {
    const besoinEstime = (rowData?.montant ?? 0) - (rowData?.montantImputationComptable ?? 0);
    if (modeCalcul === ModeCalcul.SUBSTITUER) {
      return formatMontant(besoinEstime);
    }

    return formatMontant(besoinEstime + (rowData?.montantProposition ?? 0));
  };

  const handleSave = async () => {
    const montants = [];
    emprunts.forEach((emprunt) => {
      emprunt?.children?.forEach((imputation) => {
        const besoinEstime = (imputation?.montant ?? 0) - (imputation?.montantImputationComptable ?? 0);
        montants.push({
          identifiantImputation: imputation.identifiantImputation,
          // eslint-disable-next-line
          prop: modeCalcul === ModeCalcul.SUBSTITUER ? besoinEstime : besoinEstime + (imputation?.montantProposition ?? 0),
        });
      });
    });

    try {
      setLoadingSave(true);
      await upsertMontants(simulation?.identifiant, montants);
      onValidate?.();
    } catch (e) {
      onClose?.();
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <>
      <div className="import">
        <div className="importEmpruntChoice">
          <Button.Group>
            <Button positive={modeCalcul === ModeCalcul.SUBSTITUER} onClick={handleToggleModeCalcul}>
              Substituer les besoins estimés aux propositions déjà saisies
            </Button>
            <Button.Or text="ou" />
            <Button positive={modeCalcul === ModeCalcul.CUMULER} onClick={handleToggleModeCalcul}>
              Cumuler les besoins estimés aux propositions déjà saisies
            </Button>
          </Button.Group>
        </div>
        <Table
          className="empruntTable"
          data={emprunts}
          hover={false}
          isTree
          locale={{ emptyMessage: 'Aucune donnée', loading: 'Chargement' }}
          rowKey="key"
          rowClassName="imputationTableRow"
          rowHeight={ROW_HEIGHT}
          virtualized
          wordWrap={false}
          headerHeight={60}
          autoHeight
          renderTreeToggle={(
            expandButton,
            { key },
            isExpanded,
          ) => renderTreeToggle(key, isExpanded, '')}
        >
          {({ Cell, Column, HeaderCell }) => (
            <>
              <Column
                flexGrow={4}
                fullText
                verticalAlign="middle"
              >
                <HeaderCell>
                  {`Ligne budgétaire ${simulation?.exercice?.anneeExercice}`}
                </HeaderCell>
                <Cell dataKey="libelle">
                  {(rowData) => renderLibelleRow(rowData)}
                </Cell>
              </Column>

              <Column
                flexGrow={2}
                fullText
                verticalAlign="middle"
                align="right"
              >
                <HeaderCell>
                  Echéance annuelle
                  <br />
                  (A)
                </HeaderCell>
                <Cell dataKey="montant">
                  {(rowData) => formatMontant(rowData?.montant)}
                </Cell>
              </Column>

              <Column
                flexGrow={2}
                fullText
                verticalAlign="middle"
                align="right"
              >
                <HeaderCell>
                  Crédit déjà ouvert
                  <br />
                  (B)
                </HeaderCell>
                <Cell dataKey="montantImputationComptable">
                  {(rowData) => formatMontant(rowData?.montantImputationComptable)}
                </Cell>
              </Column>

              <Column
                flexGrow={2}
                fullText
                verticalAlign="middle"
                align="right"
              >
                <HeaderCell>
                  Besoin estimé
                  <br />
                  (C = A - B)
                </HeaderCell>
                <Cell dataKey="besoinEstime">
                  {(rowData) => formatMontant((rowData?.montant ?? 0) - (rowData?.montantImputationComptable ?? 0))}
                </Cell>
              </Column>

              <Column
                flexGrow={2}
                fullText
                verticalAlign="middle"
                align="right"
              >
                <HeaderCell>
                  Proposition déjà saisie
                  <br />
                  (D)
                </HeaderCell>
                <Cell dataKey="montantProposition">
                  {(rowData) => formatMontant(rowData?.montantProposition)}
                </Cell>
              </Column>
              <Column
                flexGrow={2}
                fullText
                verticalAlign="middle"
                align="right"
              >
                <HeaderCell>
                  Proposition à appliquer
                  <br />
                  {modeCalcul === ModeCalcul.SUBSTITUER ? '(E = C)' : '(E = C + D)'}
                </HeaderCell>
                <Cell dataKey="montantPropositionApplique">
                  {(rowData) => renderPropositionAppplique(rowData)}
                </Cell>
              </Column>
            </>
          )}
        </Table>
      </div>

      <Modal.Actions className="importActions">
        <Button
          content="Appliquer ces propositions relatives aux emprunts"
          positive
          onClick={handleSave}
          loading={loadingSave}
        />
      </Modal.Actions>
    </>
  );
};

export default ImportEmprunt;
