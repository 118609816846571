import React from 'react';
import TypePeriode from '../../../Simulation/enums/typePeriode';
import TypeRegroupement from '../../constants/typeRegroupement';
import { TreeRow } from '../../interfaces/treeRow';
import { getMontantImputation } from '../../utils/imputation';
import BaseTypeRegroupement from './BaseTypeRegroupement';
import type BaseFeuilleSaisie from '../FeuilleSaisie/BaseFeuilleSaisie';

class SensTypeRegroupement extends BaseTypeRegroupement {
  constructor() {
    super();
    this.code = TypeRegroupement.SENS;
    this.codeColumnName = 'imp_sens';
    this.icon = {
      iconSet: 'Lucide',
      name: 'Book',
    };
    this.libelle = 'Sens';
  }

  getLibelle(data: TreeRow, _feuille: BaseFeuilleSaisie): string {
    if (data[this.codeColumnName] === 'R') {
      return 'Recette';
    }

    if (data[this.codeColumnName] === 'D') {
      return 'Dépense';
    }

    return '';
  }

  getTabLibelle(data: TreeRow): JSX.Element {
    return (<>{this.getLibelle(data, null)}</>);
  }

  getSolde(data: TreeRow) {
    const depenseRow = data?.children?.find((row) => row.imp_sens === 'D');
    const recetteRow = data?.children?.find((row) => row.imp_sens === 'R');

    return {
      libelle: 'SOLDE',
      budget: (recetteRow?.budget ?? 0) - (depenseRow?.budget ?? 0),
      realise: (recetteRow?.realise ?? 0) - (depenseRow?.realise ?? 0),
      report: (recetteRow?.report ?? 0) - (depenseRow?.report ?? 0),
      liquide_n: (recetteRow?.liquide_n ?? 0) - (depenseRow?.liquide_n ?? 0),
      engage_n: (recetteRow?.engage_n ?? 0) - (depenseRow?.engage_n ?? 0),
      solde: (recetteRow?.solde ?? 0) - (depenseRow?.solde ?? 0),
      montantDemande: ((getMontantImputation(recetteRow, TypePeriode.DEMANDE) ?? 0)
        - (getMontantImputation(depenseRow, TypePeriode.DEMANDE) ?? 0)),
      montantProp: ((getMontantImputation(recetteRow, TypePeriode.PROPOSE) ?? 0)
        - (getMontantImputation(depenseRow, TypePeriode.PROPOSE) ?? 0)),
      montantVote: ((getMontantImputation(recetteRow, TypePeriode.VOTE) ?? 0)
      - (getMontantImputation(depenseRow, TypePeriode.VOTE) ?? 0)),
      rar: (recetteRow?.rar ?? 0) - (depenseRow?.rar ?? 0),
    };
  }
}

export default SensTypeRegroupement;
