export enum TypeCalculRegleCalcul {
  AUCUN = 0,
  ADDITION = 1,
  SOUSTRACTION = 2,
  MOYENNE = 3,
}

export enum TypeCalculRegleCalculText {
  'Aucun',
  'Addition',
  'Soustraction',
  'Moyenne',
}

export const TYPE_CALCUL_REGLE_CALCUL_OPTIONS = [
  {
    'data-testid': `typeCalcul${TypeCalculRegleCalcul.ADDITION}`,
    key: TypeCalculRegleCalcul.ADDITION,
    text: TypeCalculRegleCalculText[TypeCalculRegleCalcul.ADDITION],
    value: TypeCalculRegleCalcul.ADDITION,
  },
  {
    'data-testid': `typeCalcul${TypeCalculRegleCalcul.SOUSTRACTION}`,
    key: TypeCalculRegleCalcul.SOUSTRACTION,
    text: TypeCalculRegleCalculText[TypeCalculRegleCalcul.SOUSTRACTION],
    value: TypeCalculRegleCalcul.SOUSTRACTION,
  },
  {
    'data-testid': `typeCalcul${TypeCalculRegleCalcul.MOYENNE}`,
    key: TypeCalculRegleCalcul.MOYENNE,
    text: TypeCalculRegleCalculText[TypeCalculRegleCalcul.MOYENNE],
    value: TypeCalculRegleCalcul.MOYENNE,
  },
] as const;
