import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import FamilleControleCoherence from '../../enums/FamilleControleCoherence';
import StatutControleCoherence from '../../enums/StatutControleCoherence';
import BaseControleCoherence from './BaseControleCoherence';
import type MontantControleCoherence from '../../interfaces/MontantControleCoherence';

class EquilibreSectionFonctionnement extends BaseControleCoherence {
  constructor(montants: MontantControleCoherence) {
    super(montants);
    this.famille = FamilleControleCoherence.EQUILIBRE_BUDGETAIRE;
  }

  getExplicatif(): string {
    const depenseFonctionnement = formatMontant(this.montants.depenseFonctionnement, 2, true);
    const recetteFonctionnement = formatMontant(this.montants.recetteFonctionnement, 2, true);

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return `Le total des prévisions inscrites en dépenses de fonctionnement (${depenseFonctionnement}) est supérieur aux recettes de fonctionnement (${recetteFonctionnement}).`;
      case StatutControleCoherence.WARNING:
        return `Le total des prévisions inscrites en recettes de fonctionnement (${recetteFonctionnement}) est supérieur aux dépenses de fonctionnement (${depenseFonctionnement}) : cela n'est autorisé qu'en présence de recettes grévées d'affectation spéciale.`;
      case StatutControleCoherence.OK:
        return `Le total des prévisions budgétaires inscrites en dépenses de fonctionnement est égal aux recettes de fonctionnement (${recetteFonctionnement}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return 'Déséquilibre de la section de fonctionnement';
      case StatutControleCoherence.WARNING:
        return 'Suréquilibre de la section de fonctionnement';
      case StatutControleCoherence.OK:
        return 'Équilibre de la section de fonctionnement';
      default:
        return '';
    }
  }

  getStatut(): number {
    const { depenseFonctionnement, recetteFonctionnement } = this.montants;

    if (depenseFonctionnement === recetteFonctionnement) {
      return StatutControleCoherence.OK;
    }

    if (depenseFonctionnement < recetteFonctionnement) {
      return StatutControleCoherence.WARNING;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default EquilibreSectionFonctionnement;
