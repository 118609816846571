import FeuilleSaisieCode from '../../constants/feuilleSaisieCode';
import BaseFeuilleSaisie from './BaseFeuilleSaisie';
import AnalytiqueTypeRegroupement from '../Regroupement/AnalytiqueTypeRegroupement';
import ChapitreTypeRegroupement from '../Regroupement/ChapitreTypeRegroupement';
import ImputationTypeRegroupement from '../Regroupement/ImputationTypeRegroupement';
import SensTypeRegroupement from '../Regroupement/SensTypeRegroupement';
import VentilationTypeRegroupement from '../Regroupement/VentilationTypeRegroupement';
import FonctionTypeRegroupement from '../Regroupement/FonctionTypeRegroupement';
import TypeRegroupement from '../../constants/typeRegroupement';
import ArticleTypeRegroupement from '../Regroupement/ArticleTypeRegroupement';
import OperationTypeRegroupement from '../Regroupement/OperationTypeRegroupement';

class InvestissementChapitreFeuilleSaisie extends BaseFeuilleSaisie {
  constructor() {
    super();
    this.code = FeuilleSaisieCode.INVESTISSEMENT_PAR_CHAPITRE;
    this.isCreditReporteShown = true;
    this.libelle = 'Investissement (par chapitre)';
    this.defaultCodeFinArbo = TypeRegroupement.ARTICLE;
    this.nodes = [
      [new SensTypeRegroupement()],
      [new ChapitreTypeRegroupement()],
      [new ArticleTypeRegroupement()],
      [new OperationTypeRegroupement()],
      [new FonctionTypeRegroupement()],
      [new AnalytiqueTypeRegroupement()],
      [new VentilationTypeRegroupement(), new ImputationTypeRegroupement()],
    ];

    this.icon = {
      iconSet: 'Lucide',
      name: 'Building2',
    };
  }
}

export default InvestissementChapitreFeuilleSaisie;
