import type BaseFeuilleSaisie from '../classes/FeuilleSaisie/BaseFeuilleSaisie';
import DotationsAuxAmortissementFeuilleSaisie from '../classes/FeuilleSaisie/DotationsAuxAmortissementFeuilleSaisie';
import FonctionnementFeuilleSaisie from '../classes/FeuilleSaisie/FonctionnementFeuilleSaisie';
import InvestissementChapitreFeuilleSaisie from '../classes/FeuilleSaisie/InvestissementChapitreFeuilleSaisie';
import InvestissementOperationFeuilleSaisie from '../classes/FeuilleSaisie/InvestissementOperationFeuilleSaisie';
import OperationOrdreBudgetaireFeuilleSaisie from '../classes/FeuilleSaisie/OperationOrdreBudgetaireFeuilleSaisie';

const FEUILLES_SAISIES: BaseFeuilleSaisie[] = [
  new DotationsAuxAmortissementFeuilleSaisie(),
  new FonctionnementFeuilleSaisie(),
  new InvestissementChapitreFeuilleSaisie(),
  new InvestissementOperationFeuilleSaisie(),
  new OperationOrdreBudgetaireFeuilleSaisie(),
];

export default FEUILLES_SAISIES;
