import React from 'react';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import { Icon } from '@jvs-group/jvs-mairistem-composants';
import type RegleCalcul from '../../../../Simulation/interfaces/regleCalcul';
import './RegleCalculListRow.less';

interface RegleCalculListRowProps {
  onDelete: (regleCalcul: RegleCalcul) => void;
  onEdit: (regleCalcul: RegleCalcul) => void;
  regleCalcul: RegleCalcul;
}

const RegleCalculListRow = ({
  onDelete,
  onEdit,
  regleCalcul,
}: RegleCalculListRowProps) => {
  const handleDelete = () => onDelete(regleCalcul);

  const handleEdit = () => onEdit(regleCalcul);

  return (
    <ListeTable.Row key={regleCalcul.identifiant}>
      <ListeTable.Cell name="libelle" verticalAlign="middle">
        <div
          className="regleCalculRow"
          data-testid={`regleCalculRow${regleCalcul.libelle}`}
          onDoubleClick={handleEdit}
        >
          <span>{regleCalcul.libelle}</span>
          {!regleCalcul.defaut && (
          <div className="regleCalculActions">
            <Icon
              data-testid="regleCalculEdit"
              iconSet="Lucide"
              name="SquarePen"
              onClick={handleEdit}
            />
            <Icon
              color="red"
              data-testid="regleCalculDelete"
              iconSet="Lucide"
              name="X"
              onClick={handleDelete}
            />
          </div>
          )}
        </div>
      </ListeTable.Cell>
    </ListeTable.Row>
  );
};

export default RegleCalculListRow;
