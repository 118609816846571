import React from 'react';
import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import './OutilTableRow.less';

export interface OutilTableRowProps {
  positive?: boolean;
  solde?: number;
  title: string;
}

const OutilTableRow = ({
  positive = true,
  solde = 0,
  title,
}: OutilTableRowProps) => (
  <div className="outilRow">
    <div className="outilRowName">
      { title }
    </div>
    <div className={`outilRowAmount ${positive ? 'positiveAmount' : 'negativeAmount'}`}>
      { formatMontant(solde) }
    </div>
  </div>
);

export default OutilTableRow;
