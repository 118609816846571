import FeuilleSaisieCode from '../../constants/feuilleSaisieCode';
import BaseFeuilleSaisie from './BaseFeuilleSaisie';
import AnalytiqueTypeRegroupement from '../Regroupement/AnalytiqueTypeRegroupement';
import ChapitreTypeRegroupement from '../Regroupement/ChapitreTypeRegroupement';
import ImputationTypeRegroupement from '../Regroupement/ImputationTypeRegroupement';
import OperationTypeRegroupement from '../Regroupement/OperationTypeRegroupement';
import SensTypeRegroupement from '../Regroupement/SensTypeRegroupement';
import VentilationTypeRegroupement from '../Regroupement/VentilationTypeRegroupement';
import FonctionTypeRegroupement from '../Regroupement/FonctionTypeRegroupement';
import TypeRegroupement from '../../constants/typeRegroupement';
import ArticleTypeRegroupement from '../Regroupement/ArticleTypeRegroupement';

class InvestissementOperationFeuilleSaisie extends BaseFeuilleSaisie {
  constructor() {
    super();
    this.chapitreArticleLie = true;
    this.code = FeuilleSaisieCode.INVESTISSEMENT_PAR_OPERATION;
    this.isCreditReporteShown = true;
    this.libelle = 'Investissement (par opération)';
    this.defaultCodeFinArbo = TypeRegroupement.CHAPITRE;
    this.nodes = [
      [new OperationTypeRegroupement()],
      [new SensTypeRegroupement()],
      [new ChapitreTypeRegroupement()],
      [new ArticleTypeRegroupement()],
      [new FonctionTypeRegroupement()],
      [new AnalytiqueTypeRegroupement()],
      [new VentilationTypeRegroupement(), new ImputationTypeRegroupement()],
    ];

    this.icon = {
      iconSet: 'Lucide',
      name: 'Building',
    };
  }
}

export default InvestissementOperationFeuilleSaisie;
