enum TypeRegroupement {
  SENS = 'sens',
  SECTION = 'section',
  CHAPITRE = 'codeChapitre',
  ARTICLE = 'codeArticle',
  OPERATION = 'codeOperation',
  FONCTION = 'codeFonction',
  ANALYTIQUE = 'codeAnalytique',
  VENTILATION = 'codeVentilation',
  IMPUTATION = 'imputation',
}

export default TypeRegroupement;
