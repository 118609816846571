import React from 'react';
import { Form, type DropdownProps } from '@jvs-group/jvs-mairistem-composants';
import TypePeriode from '../../Simulation/enums/typePeriode';
import './PeriodeDropdown.less';

const OPTIONS = [
  {
    'data-testid': `periode${TypePeriode.PROPOSE}`,
    key: TypePeriode.PROPOSE,
    text: 'Formulation des propositions',
    value: TypePeriode.PROPOSE,
  },
  {
    'data-testid': `periode${TypePeriode.VOTE}`,
    key: TypePeriode.VOTE,
    text: 'Vote par l’assemblée',
    value: TypePeriode.VOTE,
  },
];

interface PeriodeDropdownProps {
  onChange: (periode: TypePeriode) => void;
  value: number;
}

const PeriodeDropdown = ({
  onChange,
  value,
}: PeriodeDropdownProps) => {
  const handleChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    onChange?.(Number(data?.value));
  };

  return (
    <Form.Select
      className="periodeDropdown"
      data-testid="periodesDropdown"
      fluid
      inline
      onChange={handleChange}
      options={OPTIONS}
      value={value}
    />
  );
};

export default PeriodeDropdown;
