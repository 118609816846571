import TypeRegroupement from '../../constants/typeRegroupement';
import { TreeRow } from '../../interfaces/treeRow';
import BaseTypeRegroupement from './BaseTypeRegroupement';
import type BaseFeuilleSaisie from '../FeuilleSaisie/BaseFeuilleSaisie';

class ChapitreTypeRegroupement extends BaseTypeRegroupement {
  constructor(chapitreArticleLie: boolean = false) {
    super();
    this.code = TypeRegroupement.CHAPITRE;
    this.codeColumnName = 'cha_code';
    this.libelleColumnName = 'cha_libelle';
    this.icon = {
      iconSet: 'Lucide',
      name: 'FolderClosed',
    };
    this.libelle = chapitreArticleLie ? 'Article' : 'Chapitre';
  }

  getLibelle(data : TreeRow, feuille: BaseFeuilleSaisie): string {
    if (feuille.chapitreArticleLie) {
      return null;
    }
    return super.getLibelle(data, feuille);
  }
}

export default ChapitreTypeRegroupement;
