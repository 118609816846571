import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import FamilleControleCoherence from '../../enums/FamilleControleCoherence';
import StatutControleCoherence from '../../enums/StatutControleCoherence';
import BaseControleCoherence from './BaseControleCoherence';
import TypeBudget from '../../../Simulation/enums/typeBudget';
import type MontantControleCoherence from '../../interfaces/MontantControleCoherence';

class EquilibreOperationOrdreTransfertInvestissement extends BaseControleCoherence {
  constructor(montants: MontantControleCoherence) {
    super(montants);
    this.famille = FamilleControleCoherence.OPERATIONS_ORDRE;
  }

  getExplicatif(typeBudget: TypeBudget): string {
    const depenseInvestissement040 = formatMontant(this.montants.depenseInvestissement040, 2, true);
    const recetteFonctionnement042 = formatMontant(this.montants.recetteFonctionnement042, 2, true);

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        if (typeBudget === TypeBudget.COMPTE_ADMINISTRATIF) {
          return `Le total des dépenses d'investissement réalisées au chapitre 040 (${depenseInvestissement040}) diffère des recettes de fonctionnement réalisées au 042 (${recetteFonctionnement042}).`;
        }
        return `Le total des prévisions budgétaires inscrites en dépenses d'investissement au chapitre 040 (${depenseInvestissement040}) diffère des recettes de fonctionnement inscrites au 042 (${recetteFonctionnement042}).`;
      case StatutControleCoherence.OK:
        if (typeBudget === TypeBudget.COMPTE_ADMINISTRATIF) {
          return `Le total des dépenses d'investissement réalisées au chapitre 040 est égal aux recettes de fonctionnement réalisées au 042 (${depenseInvestissement040}).`;
        }
        return `Le total des prévisions budgétaires inscrites en dépenses d'investissement au chapitre 040 est égal aux recettes de fonctionnement inscrites au 042 (${depenseInvestissement040}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return 'Déséquilibre des opérations d’ordre de transfert de la section d\'investissement vers la section de fonctionnement';
      case StatutControleCoherence.OK:
        return 'Équilibre des opérations d’ordre de transfert de la section d\'investissement vers la section de fonctionnement';
      default:
        return '';
    }
  }

  getStatut(): number {
    const { depenseInvestissement040, recetteFonctionnement042 } = this.montants;

    if (depenseInvestissement040 === recetteFonctionnement042) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default EquilibreOperationOrdreTransfertInvestissement;
