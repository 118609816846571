import React from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Combobox,
  Modal,
  Table,
} from '@jvs-group/jvs-mairistem-composants';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import type OutilModalProps from '../interfaces/OutilModalProps';
import type ImputationSimulation from '../../../interfaces/imputationSimulation';
import { calculResteRealise, getRAR } from '../utils/outil';
import TypeCalculRAR from '../enums/TypeCalculRAR';

export interface RARDropdownValues {
  typeCalculDepenseFonctionnement: TypeCalculRAR;
  typeCalculDepenseInvestissement: TypeCalculRAR;
  typeCalculRecetteFonctionnement: TypeCalculRAR;
  typeCalculRecetteInvestissement: TypeCalculRAR;
}

const getOptions = (isInvestissement = false) => [
  {
    'data-testid': `option${TypeCalculRAR.RIEN_FAIRE}`,
    key: TypeCalculRAR.RIEN_FAIRE,
    text: 'ne pas calculer',
    value: TypeCalculRAR.RIEN_FAIRE,
  },
  {
    'data-testid': `option${TypeCalculRAR.ENGAGE_NON_LIQUIDE}`,
    key: TypeCalculRAR.ENGAGE_NON_LIQUIDE,
    text: isInvestissement ? 'reprendre le montant des engagements en cours'
      : 'reprendre le montant des engagements en cours et au service non fait',
    value: TypeCalculRAR.ENGAGE_NON_LIQUIDE,
  },
  {
    'data-testid': `option${TypeCalculRAR.BUDGET_REALISE}`,
    key: TypeCalculRAR.BUDGET_REALISE,
    text: 'reprendre le solde positif (budget - liquidé)',
    value: TypeCalculRAR.BUDGET_REALISE,
  },
  {
    'data-testid': `option${TypeCalculRAR.REMISE_A_ZERO}`,
    key: TypeCalculRAR.REMISE_A_ZERO,
    text: 'remettre à zéro',
    value: TypeCalculRAR.REMISE_A_ZERO,
  },
];

const CalculRARModal = ({
  onClose,
  onValidate,
  open,
  simulation,
}: OutilModalProps) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState<boolean>(false);
  const [dropdownValues, setDropdownValues] = React.useState<RARDropdownValues>({
    typeCalculDepenseFonctionnement: TypeCalculRAR.RIEN_FAIRE,
    typeCalculDepenseInvestissement: TypeCalculRAR.RIEN_FAIRE,
    typeCalculRecetteFonctionnement: TypeCalculRAR.RIEN_FAIRE,
    typeCalculRecetteInvestissement: TypeCalculRAR.RIEN_FAIRE,
  });
  const [loading, setLoading] = React.useState<boolean>(false);

  const clearDropdownValues = () => {
    setDropdownValues({
      typeCalculDepenseFonctionnement: TypeCalculRAR.RIEN_FAIRE,
      typeCalculDepenseInvestissement: TypeCalculRAR.RIEN_FAIRE,
      typeCalculRecetteFonctionnement: TypeCalculRAR.RIEN_FAIRE,
      typeCalculRecetteInvestissement: TypeCalculRAR.RIEN_FAIRE,
    });
  };

  // Recuperation des RAR pour savoir si on affiche la modal de confirmation
  const fetchRAR = async () => {
    let data: ImputationSimulation[];

    try {
      const response = await getRAR(simulation?.identifiant);
      data = response?.data;
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors de la récupération des RAR'));
    }

    return data;
  };

  const handleCalculRAR = async () => {
    try {
      setLoading(true);
      await calculResteRealise(simulation?.identifiant, dropdownValues);
      clearDropdownValues();
      onValidate?.();
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors du calcul des RAR'));
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e, { name, value }: { name: string, value: number }) => {
    setDropdownValues((old) => ({
      ...old,
      [name]: value,
    }));
  };

  const handleClose = () => {
    clearDropdownValues();
    onClose?.();
  };

  const handleCloseConfirmation = () => setConfirmationModalOpen(false);

  const handleValidate = async () => {
    const listRAR = await fetchRAR();

    if (!listRAR) return;

    // Sinon, on regarde si nous avons des RAR et que toutes les comboboxs sont a "ne pas calculer"
    const hasRAR = !isEmpty(listRAR);
    const hasOnlyNoRecalculCombo = every(
      values(dropdownValues),
      (dropdownValue) => dropdownValue === TypeCalculRAR.RIEN_FAIRE,
    );

    if (hasRAR && !hasOnlyNoRecalculCombo) {
      setConfirmationModalOpen(true);
    } else {
      await handleCalculRAR();
    }
  };

  const handleValidateConfirmation = async () => {
    setConfirmationModalOpen(false);
    await handleCalculRAR();
  };

  return (
    <>
      <Modal
        closeIcon
        onClose={handleClose}
        open={open}
      >
        <Modal.Header content="Calculer les Restes à réaliser" />
        <Modal.Content className="calculRARModalContent">
          <Table celled definition>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell textAlign="center">Dépense</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Recette</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {
                  simulation?.exercice?.budReportFonc && (
                  <Table.Row>
                    <Table.Cell collapsing textAlign="center" verticalAlign="middle">Fonctionnement</Table.Cell>
                    <Table.Cell textAlign="center">
                      <Combobox
                        data-testid="depenseFonctionnementDropdown"
                        menuPortalTarget={document.body}
                        name="typeCalculDepenseFonctionnement"
                        onChange={handleChange}
                        options={getOptions()}
                        selection
                        value={dropdownValues.typeCalculDepenseFonctionnement}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Combobox
                        data-testid="recetteFonctionnementDropdown"
                        menuPortalTarget={document.body}
                        name="typeCalculRecetteFonctionnement"
                        onChange={handleChange}
                        options={getOptions()}
                        selection
                        value={dropdownValues.typeCalculRecetteFonctionnement}
                      />
                    </Table.Cell>
                  </Table.Row>
                  )
              }
              <Table.Row>
                <Table.Cell textAlign="center" verticalAlign="middle">Investissement</Table.Cell>
                <Table.Cell textAlign="center">
                  <Combobox
                    data-testid="depenseInvestissementDropdown"
                    menuPortalTarget={document.body}
                    name="typeCalculDepenseInvestissement"
                    onChange={handleChange}
                    options={getOptions(true)}
                    selection
                    value={dropdownValues.typeCalculDepenseInvestissement}
                  />
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Combobox
                    data-testid="recetteInvestissementDropdown"
                    menuPortalTarget={document.body}
                    name="typeCalculRecetteInvestissement"
                    onChange={handleChange}
                    options={getOptions(true)}
                    selection
                    value={dropdownValues.typeCalculRecetteInvestissement}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Calculer"
            data-testid="confirmButton"
            loading={loading}
            onClick={handleValidate}
            positive
          />
        </Modal.Actions>
      </Modal>
      { /* Modal de confirmation */ }
      <Modal
        closeIcon
        onClose={handleCloseConfirmation}
        open={confirmationModalOpen}
      >
        <Modal.Header content="Attention" />
        <Modal.Content>
          Des restes à réaliser sont déjà saisis.
          <br />
          Confirmez-vous leur recalcul ?
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            content="Annuler"
            data-testid="closeButton"
            icon="times"
            onClick={handleCloseConfirmation}
            size="tiny"
          />
          <Button
            content="Oui"
            data-testid="confirmButton"
            onClick={handleValidateConfirmation}
            positive
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default CalculRARModal;
