import type { TemplateRequestGet } from '@jvs-group/jvs-mairistem-finances-utils';
import { request } from '@jvs-group/jvs-mairistem-store';
import isEmpty from 'lodash/isEmpty';
import type Reports from '../interfaces/Reports';
import type ImputationEmprunt from '../interfaces/ImputationEmprunt';
import type Simulation from '../../Simulation/interfaces/simulation';
import type ImputationSimulation from '../../../interfaces/imputationSimulation';
import type { RARDropdownValues } from '../components/CalculRARModal';

export const recopieReport = (simulation: Simulation, values?: object) => {
  const params = new URLSearchParams();

  if (values) {
    Object.keys(values).forEach((key) => {
      const value = values[key];
      if (!isEmpty(value)) params.append(key, value);
    });
  }

  params.append('typePeriode', String(simulation?.typePeriode));

  return request.get<unknown, unknown>(
    `/api/finances/simulation/${simulation?.identifiant}/recopieReport?${params.toString()}`,
  );
};

export const getReports = async (identifiant: number) => request.get<any, Reports>(
  `/api/finances/simulation/${identifiant}/getReports`,
);

export const calculVirementSection = (simulation: Simulation) => request.get<unknown, unknown>(
  `/api/finances/simulation/${simulation.identifiant}/calculVirementSection?typePeriode=${simulation.typePeriode}`,
);

export const getVirementsSection = async (simulation: Simulation) => request.get<any, any>(
  `/api/finances/simulation/${simulation.identifiant}/getVirementsSection?typePeriode=${simulation.typePeriode}`,
);

export const transfertComptabilite = async (identifiant: number) => request.get<any, any>(
  `/api/finances/simulation/${identifiant}/transfertComptabilite`,
);

export const annulationTransfertComptabilite = async (identifiant: number) => request.get<any, any>(
  `/api/finances/simulation/${identifiant}/annulationTransfertComptabilite`,
);

export const propToVote = async (identifiant: number) => request.get<any, any>(
  `/api/finances/simulation/${identifiant}/propToVote`,
);

export const annulationVote = async (identifiant: number) => request.get<any, any>(
  `/api/finances/simulation/${identifiant}/annulationVote`,
);

export const getRepriseRestesARealiser = async (identifiant: number) => request.get<any, any>(
  `/api/finances/simulation/${identifiant}/getRepriseRestesARealiser`,
);

export const repriseRestesARealiser = async (identifiant: number) => request.get<any, any>(
  `/api/finances/simulation/${identifiant}/repriseRestesARealiser`,
);

export const importEmprunt = (identifiant: number) => request.get<unknown, ImputationEmprunt[]>(
  `/api/finances/simulation/${identifiant}/importEmprunt`,
);

export const upsertMontants = (
  identifiantSimulation: number,
  montants: any,
) => request.put<unknown, unknown>(
  '/api/finances/simulation/montantSimulation',
  {
    montants,
    identifiantSimulation,
  },
);

export const getControleCoherence = ({ identifiant, typePeriode }: Simulation) => request.get<any, any>(
  `/api/finances/simulation/${identifiant}/controleCoherencePrevisions?typePeriode=${typePeriode}`,
);

export const getRAR = (identifiant: number) => request.get<any, TemplateRequestGet<ImputationSimulation>>(
  `/api/finances/simulation/imputationSimulation?identifiantSimulation=${identifiant}&rar=not(0)&perPage=1`,
);

export const calculResteRealise = (identifiant: number, values: RARDropdownValues) => {
  const params = new URLSearchParams();

  Object.entries(values).forEach(([key, value]) => {
    params.append(key, value.toString());
  });

  return request.get(`/api/finances/simulation/${identifiant}/calculResteRealise?${params.toString()}`);
};
