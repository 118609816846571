import React from 'react';
import {
  Button,
  Modal,
} from '@jvs-group/jvs-mairistem-composants';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import type OutilModalProps from '../interfaces/OutilModalProps';
import { annulationVote, propToVote } from '../utils/outil';

const RecopiePropositionEnVoteModal = ({
  onClose,
  onValidate,
  open,
  simulation,
}: OutilModalProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleAnnulation = async () => {
    try {
      setLoading(true);
      await annulationVote(simulation?.identifiant);
      onValidate?.();
    } catch (e) {
      toast.error(getErrorMessage(e, "Erreur lors de l'annulation du vote"));
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => onClose?.();

  const handleVote = async () => {
    try {
      setLoading(true);
      await propToVote(simulation?.identifiant);
      onValidate?.();
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors du vote'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      closeIcon
      onClose={handleClose}
      open={open}
    >
      <Modal.Header
        content={simulation?.vote ? 'Remettre à zéro les montants votés'
          : 'Recopier les propositions dans les montants votés'}
      />
      <Modal.Content>
        {
          simulation?.vote
            ? (
              <>
                Avec cette remise à 0, les montants votés saisis vont être perdus.
                <br />
                Vous pourrez en saisir de nouveaux, manuellement ligne par ligne,
                ou recopier globalement toutes les propositions formulées par l&apos;ordonnateur.
              </>
            )
            : (
              <>
                Pour chaque ligne budgétaire, le montant du vote va être mis à jour avec le montant de la proposition.
                <br />
                Vous pourrez ensuite modifier ce montant sur les lignes pour lesquelles
                l&apos;assemblée délibérante n&apos;a pas adopté
                la proposition budgétaire formulée par l&apos;ordonnateur.
              </>
            )
        }
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Annuler"
          data-testid="closeButton"
          onClick={handleClose}
          negative
        />
        <Button
          content="Confirmer"
          data-testid="confirmButton"
          disabled={loading}
          loading={loading}
          onClick={simulation?.vote ? handleAnnulation : handleVote}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default RecopiePropositionEnVoteModal;
