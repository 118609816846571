import React from 'react';
import { Grid, Menu, Segment } from '@jvs-group/jvs-mairistem-composants';
import FeuilleSaisieList from './FeuilleSaisie/FeuilleSaisieList';
import './parametres.less';
import RegleCalculList from './RegleCalcul/List/RegleCalculList';
import { getExercice } from '../../FeuilleSaisie/utils/exercice';
import StorageKey from '../../../constants/storage';

const TAB = {
  FEUILLE_SAISIE: 'Feuille de saisie',
  REGLE_CALCUL: 'Règles de calcul personnalisées',
};

const Parametres = () => {
  const [currentTab, setCurrentTab] = React.useState(TAB.FEUILLE_SAISIE);
  const [exercice, setExercice] = React.useState(null);
  const fetchExercice = async () => {
    const identifiantExercice = Number(sessionStorage.getItem(StorageKey.IDENTIFIANT_EXERCICE));
    if (identifiantExercice) {
      setExercice(await getExercice(identifiantExercice));
    }
  };

  React.useEffect(() => {
    fetchExercice();
  }, []);

  const handleOpenMenu = (e, { name }) => {
    setCurrentTab(name);
  };

  return (
    <Grid id="parametres">
      <Grid.Row>
        <Grid.Column
          widescreen={2}
          largeScreen={2}
          computer={2}
          tablet={5}
          mobile={16}
          textAlign="center"
          className="wrapper"
          stackable
        >
          <Menu pointing vertical fluid>
            <Menu.Item
              active={currentTab === TAB.FEUILLE_SAISIE}
              data-testid="menuFeuilleSaisie"
              name={TAB.FEUILLE_SAISIE}
              onClick={handleOpenMenu}
            >
              {TAB.FEUILLE_SAISIE}
            </Menu.Item>
            <Menu.Item
              active={currentTab === TAB.REGLE_CALCUL}
              data-testid="menuRegleCalcul"
              name={TAB.REGLE_CALCUL}
              onClick={handleOpenMenu}
            >
              {TAB.REGLE_CALCUL}
            </Menu.Item>
          </Menu>
        </Grid.Column>
        <Grid.Column
          widescreen={14}
          largeScreen={14}
          computer={14}
          tablet={11}
          mobile={16}
          stretched
          id="menu-content"
        >
          <Segment attached="bottom">
            { currentTab === TAB.FEUILLE_SAISIE && <FeuilleSaisieList exercice={exercice} /> }
            { currentTab === TAB.REGLE_CALCUL && <RegleCalculList /> }
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>

  );
};

export default Parametres;
