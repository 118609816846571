import React from 'react';
import {
  Card,
  Grid,
  Segment,
  SemanticWIDTHS,
  type IconJVSProps,
} from '@jvs-group/jvs-mairistem-composants';
import './Panel.less';
import { Icon } from '@jvs-group/jvs-mairistem-finances-utils';

interface PanelProps {
  code: string,
  icon: IconJVSProps,
  onRemove: () => void,
  size: SemanticWIDTHS,
  children: any,
}

const Panel = ({
  code = null,
  icon = null,
  onRemove = null,
  size = null,
  children = null,
}: PanelProps) => (
  <Grid.Column width={size} className="panelColumn">
    <Card fluid as={Segment}>
      <Card.Content className="flex panedCardContent">
        {icon && (
        <div className="panelIconContainer">
          <Icon
            {...icon}
            size={36}
          />
        </div>
        )}
        <Grid className="panelDataContainer">
          <Grid.Row stretched>
            <Grid.Column width={16}>
              {children}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Icon
          className="removeIcon"
          data-testid={`removeIndicateur${code}`}
          iconSet="Lucide"
          name="X"
          onClick={onRemove}
        />
      </Card.Content>
    </Card>
  </Grid.Column>
);

export default Panel;
