import TypeRegroupement from '../../constants/typeRegroupement';
import { TreeRow } from '../../interfaces/treeRow';
import BaseTypeRegroupement from './BaseTypeRegroupement';
import type BaseFeuilleSaisie from '../FeuilleSaisie/BaseFeuilleSaisie';

class ArticleTypeRegroupement extends BaseTypeRegroupement {
  constructor() {
    super();
    this.code = TypeRegroupement.ARTICLE;
    this.codeColumnName = 'art_code';
    this.libelleColumnName = 'art_libelle';
    this.icon = {
      iconSet: 'Lucide',
      name: 'File',
    };
    this.libelle = 'Article';
  }

  getLibelle(data : TreeRow, feuille: BaseFeuilleSaisie): string {
    if (feuille.chapitreArticleLie && data.isordre) {
      return `${data.art_code} (${data.cha_code}) - ${data.art_libelle}`;
    }
    return super.getLibelle(data, feuille);
  }
}

export default ArticleTypeRegroupement;
