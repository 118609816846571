import React from 'react';
import { Grid } from '@jvs-group/jvs-mairistem-composants';
import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import { getSoldeClass } from '../utils/panels';
import type PanelDataFromPeriode from '../interfaces/PanelDataFromPeriode';

interface BalanceFonctionnementProps {
  data: PanelDataFromPeriode;
}

const BalanceFonctionnement = ({
  data,
}: BalanceFonctionnementProps) => {
  const solde = (data?.recetteFonctionnement ?? 0) - (data?.depenseFonctionnement ?? 0);
  const soldeClass = getSoldeClass(solde);
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column className="bold indicateurTitle">Fonctionnement</Grid.Column>
      </Grid.Row>
      <Grid.Row stretched>
        <Grid.Column width={10}>
          <div>Dépense</div>
          <div>Recette</div>
        </Grid.Column>
        <Grid.Column width={6} textAlign="right">
          <div>{formatMontant(data?.depenseFonctionnement ?? 0)}</div>
          <div>{formatMontant(data?.recetteFonctionnement ?? 0)}</div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className={soldeClass?.join(' ')}>
        <Grid.Column width={10}> Solde </Grid.Column>
        <Grid.Column width={6} textAlign="right">
          {formatMontant(solde)}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default BalanceFonctionnement;
