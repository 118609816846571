import React from 'react';
import {
  Button,
  Dimmer,
  Loader,
  Modal,
} from '@jvs-group/jvs-mairistem-composants';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import OutilTable from './OutilTable';
import type { OutilTableRowProps } from './OutilTableRow';
import { calculVirementSection, getVirementsSection } from '../utils/outil';
import type OutilModalProps from '../interfaces/OutilModalProps';
import './CalculVirementCreditModal.less';

interface SoldesVirementCredit {
  montant: number;
}

const getRowData = (data: SoldesVirementCredit): OutilTableRowProps[] => ([
  {
    positive: false,
    solde: data?.montant ?? 0,
    title: "D 023 - Virement à la section d'investissement",
  },
  {
    positive: true,
    solde: data?.montant ?? 0,
    title: 'R 021 - Virement de la section de fonctionnement',
  },
]);

const CalculVirementCreditModal = ({
  onClose,
  onValidate,
  open,
  simulation,
}: OutilModalProps) => {
  const [loadingData, setLoadingData] = React.useState<boolean>(false);
  const [loadingValidate, setLoadingValidate] = React.useState<boolean>(false);
  const [soldes, setSoldes] = React.useState<SoldesVirementCredit>();

  const handleClose = () => onClose?.();

  const handleFetchVirementCredit = async () => {
    try {
      setLoadingData(true);
      setSoldes(await getVirementsSection(simulation));
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors de la récupération des montants du virement de crédit'));
      onClose?.();
    } finally {
      setLoadingData(false);
    }
  };

  const handleValidate = async () => {
    try {
      setLoadingValidate(true);
      await calculVirementSection(simulation);
      onValidate?.();
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors du calcul du virement de crédits'));
    } finally {
      setLoadingValidate(false);
    }
  };

  return (
    <Modal
      closeIcon
      onClose={handleClose}
      onMount={handleFetchVirementCredit}
      open={open}
    >
      <Modal.Header content="Calculer les virements de crédits entre sections (021 - 023)" />
      <Modal.Content className="virementCreditModalContent">
        {
          loadingData ? (
            <Dimmer active inverted>
              <Loader size="massive" />
            </Dimmer>
          ) : <OutilTable data={getRowData(soldes)} />
        }
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Mettre à jour les 021-023"
          data-testid="confirmButton"
          disabled={loadingData || loadingValidate}
          loading={loadingValidate}
          onClick={handleValidate}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default CalculVirementCreditModal;
