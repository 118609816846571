import React from 'react';

import {
  App,
  Feature,
  Menu,
} from '@jvs-group/jvs-mairistem-module';
import { request } from '@jvs-group/jvs-mairistem-store';
import Context from './components/Context/Context';
import Parametres from './entities/Parametres/components/Parametres';
import ElaborationBudget from './entities/Simulation/components/ElaborationBudget';
import 'react-toastify/dist/ReactToastify.css';
import './AppSimulation.less';
import StorageKey from './constants/storage';

const getIdentifiantSimulationFromUrl = () => {
  const { pathname } = window.location;
  // Diviser le chemin en segments
  const segments = pathname.split('/');
  // Récupérer le dernier segment
  const lastSegment = segments.pop() || segments.pop();
  // Pour gérer les barres obliques finales
  return lastSegment;
};

const AppSimulation = () => {
  const identifiantSimulationRef = React.useRef(getIdentifiantSimulationFromUrl());

  const renderBreadcrumb = (path, { BreadcrumbContainer, BreadcrumbSection, BreadcrumbSeparator }) => (
    <BreadcrumbContainer>
      <BreadcrumbSection key="domain">{path.domain.toLocaleUpperCase()}</BreadcrumbSection>
      <BreadcrumbSeparator />
      <BreadcrumbSection key="feature">{path.feature}</BreadcrumbSection>
    </BreadcrumbContainer>
  );

  const handleOnAuth = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    sessionStorage.setItem(StorageKey.IDENTIFIANT_EXERCICE, queryParams.get('identifiantExercice'));
  };

  return (
    <App
      breadcrumbRenderer={renderBreadcrumb}
      code="HOL_SIMU"
      context={Context}
      group="finances"
      name="Elaboration du budget"
      request={request}
      onAuth={handleOnAuth}
    >
      <Menu.Settings>
        <Feature>
          <Feature.Content>
            <Parametres />
          </Feature.Content>
        </Feature>
      </Menu.Settings>
      <Menu
        icon="home"
        name="Elaboration du budget"
        path={`/budgets/${identifiantSimulationRef.current}`}
      >
        <Feature>
          <Feature.Content>
            <ElaborationBudget identifiantSimulation={Number(identifiantSimulationRef.current)} />
          </Feature.Content>
        </Feature>
      </Menu>
    </App>
  );
};
export default AppSimulation;
