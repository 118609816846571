import TypeRegroupement from '../../constants/typeRegroupement';
import BaseTypeRegroupement from './BaseTypeRegroupement';

class VentilationTypeRegroupement extends BaseTypeRegroupement {
  constructor() {
    super();
    this.code = TypeRegroupement.VENTILATION;
    this.codeColumnName = 'ven_code';
    this.icon = {
      iconSet: 'Lucide',
      name: 'LandPlot',
    };
    this.libelleColumnName = 'ven_libelle';
    this.libelle = 'Ventilation';
  }
}

export default VentilationTypeRegroupement;
