import BaseOutil from './BaseOutil';

class ImportExportExcel extends BaseOutil {
  constructor() {
    super();
    this.code = 'IMP_EXC';
    this.icon = {
      iconSet: 'Lucide',
      name: 'ArrowDownUp',
    };
  }

  title = 'Importer / exporter un classeur bureautique';

  isEnabled() { return false; }
}

export default ImportExportExcel;
