import { toast } from 'react-toastify';
import { request } from '@jvs-group/jvs-mairistem-store';
import { isEmpty, uniqueId } from 'lodash';
import type BaseTypeRegroupement from '../classes/Regroupement/BaseTypeRegroupement';
import type BaseFeuilleSaisie from '../classes/FeuilleSaisie/BaseFeuilleSaisie';
import TypeRegroupement from '../constants/typeRegroupement';
import { TreeRow } from '../interfaces/treeRow';
import TypePeriode from '../../Simulation/enums/typePeriode';
import type Simulation from '../../Simulation/interfaces/simulation';
import { shouldAddReport } from '../../Simulation/utils/simulation';

const getItemByRegroupement = (
  item,
  typesRegroupement: BaseTypeRegroupement[],
  feuilleSaisie: BaseFeuilleSaisie,
  index: number,
) => {
  const libelle = [];
  let key = '';
  typesRegroupement.forEach((typeRegroupement) => {
    key += typeRegroupement.getKey(item);
    // Pour empêcher d'avoir un separateur supplémentaire
    if (!isEmpty(typeRegroupement.getLibelle(item, feuilleSaisie))) {
      libelle.push(typeRegroupement.getLibelle(item, feuilleSaisie) as string);
    }
  });
  return {
    key: `${uniqueId()}-${key}`,
    // utilise pour le deplacement avec le clavier
    keyOrder: `${index}-${key}`,
    libelle,
  };
};

export const fetchData = async (
  simulation: Simulation,
  feuilleSaisie: BaseFeuilleSaisie,
  typesRegroupement: BaseTypeRegroupement[],
  filters = null,
) => {
  const params = new URLSearchParams();
  typesRegroupement.forEach((typeRegroupement) => {
    params.append('typesRegroupement[]', typeRegroupement.code);
  });
  params.append('codeFeuille', feuilleSaisie.code);

  if (!isEmpty(filters)) {
    Object.entries(filters).forEach(([key, value]) => {
      params.append(key, value.toString());
    });
  }

  params.append('typePeriode', simulation?.typePeriode.toString());

  if (feuilleSaisie.isCreditReporteShown && shouldAddReport(simulation, feuilleSaisie)) {
    params.append('filterRAR', '1');
  }

  try {
    const data = await request.get<unknown, TreeRow[]>(
      `/api/finances/simulation/${simulation?.identifiant}/getImputationsByGroup?${params.toString()}`,
    );

    let index = 0;
    return data?.map((item) => ({
      ...item,
      index,
      ...getItemByRegroupement(item, typesRegroupement, feuilleSaisie, index++),
      montant_propose: Number(item.montant_propose ?? 0),
      montant_vote: Number(item.montant_vote ?? 0),
      report: Number(item.report ?? 0),
      budget: Number(item.budget),
      realise: Number(item.realise),
      rar: Number(item.rar ?? 0),
      engage_n: Number(item.engage_n ?? 0),
      liquide_n: Number(item.liquide_n ?? 0),
      solde: Number(item.budget ?? 0) - Number(item.liquide_n ?? 0),
      // Permet de gerer l'icone déplier quand on est au niveau le plus fin
      ...(typesRegroupement.find((typeRegroupement) => typeRegroupement.code === TypeRegroupement.IMPUTATION)
        ? {} : { children: [] }),
      typesRegroupement,
    }));
  } catch {
    toast.error("Erreur lors du chargement de l'arbre");
  }

  return [];
};

export const extractCodeFromKey = (key: string): string => {
  const siblingKeyParts = key.split('-');
  siblingKeyParts?.shift();
  return siblingKeyParts?.join('-');
};

export const getMontantColumnFromPeriode = (typePeriode: TypePeriode): string => {
  switch (typePeriode) {
    case TypePeriode.DEMANDE:
      return 'demande';
    case TypePeriode.PROPOSE:
      return 'prop';
    case TypePeriode.VOTE:
      return 'vote';
    case TypePeriode.CA_CFU:
      return 'rar';
    default:
      return '';
  }
};

export const getMontantNameFromPeriode = (typePeriode: TypePeriode): string => {
  switch (typePeriode) {
    case TypePeriode.DEMANDE:
      return 'montant_demande';
    case TypePeriode.PROPOSE:
      return 'montant_propose';
    case TypePeriode.VOTE:
      return 'montant_vote';
    case TypePeriode.CA_CFU:
      return 'rar';
    default:
      return '';
  }
};

export const getMontantImputation = (
  imputation : TreeRow,
  typePeriode: TypePeriode,
) => imputation?.[getMontantNameFromPeriode(typePeriode)] ?? 0;
