import React from 'react';
import BaseOutil from './BaseOutil';
import type Simulation from '../../Simulation/interfaces/simulation';
import TypeBudget from '../../Simulation/enums/typeBudget';
import ControleCoherenceModal from '../components/ControleCoherence/ControleCoherenceModal';
import type UserRole from '../../../constants/userRole';

class ControleCoherence extends BaseOutil {
  constructor(simulation: Simulation) {
    super();
    this.code = 'CTRL_CHR';
    this.icon = {
      iconSet: 'Lucide',
      name: 'ListChecks',
    };
    this.title = simulation?.budget?.type === TypeBudget.COMPTE_ADMINISTRATIF
      ? 'Contrôler la cohérence des réalisations' : 'Contrôler la cohérence des prévisions';
  }

  isEnabled(userRole: UserRole, simulation: Simulation): boolean {
    return !simulation?.transfertCpta && super.isEnabled(userRole, simulation)
      && (simulation?.exercice?.typePlan?.codeComptabilite === 'M57'
        || simulation?.exercice?.typePlan?.codeComptabilite.startsWith('M4'));
  }

  renderModal(
    simulation: Simulation,
    onClose: () => void,
    onValidate: (data: unknown) => void,
    open: boolean,
  ) {
    return (
      <ControleCoherenceModal
        onClose={onClose}
        onValidate={onValidate}
        open={open}
        simulation={simulation}
      />
    );
  }
}

export default ControleCoherence;
