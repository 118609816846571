import React from 'react';
import { Button, Menu, Popup } from '@jvs-group/jvs-mairistem-composants';
import FeuilleSaisiePopupList from './FeuilleSaisiePopupList';
import type BaseFeuilleSaisie from '../classes/FeuilleSaisie/BaseFeuilleSaisie';
import './TreeViewFeuilleSaisie.less';
import type Simulation from '../../Simulation/interfaces/simulation';

interface TreeViewFeuilleSaisieProps {
  feuillesSaisies: BaseFeuilleSaisie[];
  loading: boolean;
  onSelect: (feuille: BaseFeuilleSaisie) => void;
  simulation: Simulation,
}

const TreeViewFeuilleSaisie = ({
  feuillesSaisies,
  loading = false,
  simulation,
  onSelect,
} : TreeViewFeuilleSaisieProps) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleSelect = (feuille: BaseFeuilleSaisie) => {
    onSelect?.(feuille);
    setOpen(false);
  };

  return (
    <Popup
      basic
      className="popupFeuille"
      on="click"
      onClose={() => setOpen(false)}
      open={open}
      position="bottom left"
      trigger={(
        <Menu.Menu position="left">
          <Button
            content="Ajouter une feuille de saisie"
            data-testid="feuilleSaisieAddButton"
            icon="add"
            labelPosition="left"
            loading={loading}
            onClick={() => setOpen(true)}
            secondary
          />
        </Menu.Menu>
      )}
    >
      <FeuilleSaisiePopupList
        feuillesSaisies={feuillesSaisies}
        onSelect={handleSelect}
        simulation={simulation}
      />
    </Popup>
  );
};

export default TreeViewFeuilleSaisie;
