import React from 'react';
import OutilTableRow, { type OutilTableRowProps } from './OutilTableRow';
import './OutilTable.less';

interface OutilTableProps {
  data: OutilTableRowProps[];
}

const OutilTable = ({ data }: OutilTableProps) => (
  <div className="outilTable">
    { data?.map((row) => <OutilTableRow key={row.title} {...row} />) }
  </div>
);

export default OutilTable;
